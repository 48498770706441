import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import AddImage from 'images/add_image.png'
import { Avatar, Grid, makeStyles } from '@mui/material'
import FileUploader from 'components/FileUploader'
import { FaImage, FaRegSmileWink } from 'react-icons/fa'
import styled from 'styled-components'

import TWControls from 'components/controls/TWControls'
import { Form, useForm } from 'components/controls/useForm'
import { getLocationByID, updateLocation } from 'slices/locationSlice'
import { useParams } from 'react-router-dom'
import Layout from 'components/Layout/Layout'
import * as _ from 'styles/helpers'
import Card from 'components/Card'
import Button from 'components/Button'

const API_PATH = process.env.REACT_APP_CLOUDINARY_PATH
const API_SERVER = process.env.REACT_APP_CLOUDINARY_SERVER

const Location_Edit = ({ location }) => {
	console.log(`location: `, location)
	const { id } = useParams()
	const history = useHistory()
	const locationId = id

	const { goBack } = useHistory()

	const [owner, setOwner] = useState('')
	const [retailer, setRetailer] = useState('')
	const [assetName, setAssetName] = useState('')
	const [assetType, setAssetType] = useState('')
	const [locationType, setLocationType] = useState('')
	const [address, setAddress] = useState('')
	const [apn, setApn] = useState('')
	const [city, setCity] = useState('')
	const [stateProvince, setStateProvince] = useState('')
	const [postalCode, setPostalCode] = useState('')
	const [cbsa, setCBSA] = useState('')
	const [country, setCountry] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [partners, setPartners] = useState([])
	const [latitude, setLatitude] = useState(0)
	const [longitude, setLongitude] = useState(0)
	const [totalSqft, setSellingSqft] = useState(0)
	const [image, setImage] = useState(location.image)
	const [logo, setLogo] = useState('')
	const [updateNote, setUpdateNote] = useState('')

	const [isActive, setIsActive] = useState(true)
	const [isInFleet, setIsInFleet] = useState(false)
	const [isPOIOnly, setIsPOIOnly] = useState(false)
	const [isTenantOnly, setIsTenantOnly] = useState(false)

	const [uploading, setUploading] = useState(false)
	const [picLoading, setPicLoading] = useState(false)
	const [picLoaded, setPicLoaded] = useState(false)

	const dispatch = useDispatch()

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const locationUpdate = useSelector(state => state.locationUpdate)
	// const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = locationUpdate
	const loadingUpdate = locationUpdate?.loading
	const errorUpdate = locationUpdate?.error
	const successUpdate = locationUpdate?.success

	const locationTypes = [
		{ id: 1 },
		{ selectValue: 'Commercial Center' },
		{ id: 2 },
		{ selectValue: 'Commercial Location' },
		{ id: 3 },
		{ selectValue: 'Retail Center' },
		{ id: 4 },
		{ selectValue: 'Retail Location' },
	]

	const initialValues = {
		// ...defaultValues,
		...location,
	}

	const validate = () => {
		let temp = { ...errors }
		// temp.taskName = values.taskName ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = async e => {
		e.preventDefault()
		const postalCode = location.postalCode.substring(0, 5)

		const params = {
			params: {
				postalCode: postalCode,
			},
		}

		let c = ''
		try {
			let { data } = await axios.get(`/api/postalcodedemographics/postalcodecbsa`, params)
			c = data
		} catch (e) {
			console.error(e)
		}

		const updatedLocation = {
			_id: values.locationId,
			owner,
			assetName: values.assetName,
			assetType: values.assetType,
			locationType: values.locationType,
			isActive: values.isActive,
			isInFleet: values.isInFleet,
			isPOIOnly: values.isPOIOnly,
			isTenantOnly: values.isTenantOnly,
			retailer: values.retailer,
			address: values.address,
			apn: values.apn,
			city: values.city,
			stateProvince: values.stateProvince,
			postalCode: values.postalCode,
			cbsa: values.cbsa,
			country: values.country,
			phoneNumber: values.phoneNumber,
			latitude: values.latitude,
			longitude: values.longitude,
			totalSqft: values.totalSqft,
			estimatedSales: values.estimatedSales,
			image: values.image,
			logo: values.logo,
			partners: values.partners,
			updateNote: 'Edited Location.',
		}

		const newLocation = values

		console.log(`values: `, values)

		if (values.locationType === 'Retail Center') {
			newLocation.assetType = 'center'
		}
		newLocation.updateNote = 'Edited Location.'
		await dispatch(updateLocation(values))
		history.push(`/locations/${locationId}/edit`)
	}

	const uploadImage = async info => {
		setPicLoading(true)
		setImage(info.originalUrl)
		values.image = info.originalUrl
		setPicLoading(false)
	}

	const formReturn = () => {
		goBack()
	}

	return (
		<>
			<Layout>
				<Layout.Page>
					<Layout.Body>
						<StyledAssetEdit>
							<Form>
								<Card
									className="UserProfile__Card"
									header={{
										title: `Edit: ${values.assetName}`,
										secondary: (
											<Button isOutline onClick={() => formReturn()}>
												Return
											</Button>
										),
									}}
								>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TWControls.Input
												size="small"
												name="assetName"
												label="Location Name"
												value={values.assetName}
												onChange={handleInputChange}
												// error={errors.assetName}
											/>
										</Grid>
										<Grid item xs={3}>
											<TWControls.Input
												size="small"
												name="retailer"
												label="Retailer"
												value={values.retailer}
												onChange={handleInputChange}
											/>
										</Grid>
										<Grid item xs={3}>
											<TWControls.SelectType
												className="feed_select"
												// size="small"
												name="locationType"
												label="Location Type"
												value={values.locationType}
												onChange={handleInputChange}
												options={locationTypes}
											/>
										</Grid>
										<Grid item xs={6}>
											<TWControls.Input
												size="small"
												name="address"
												label="Address"
												value={values.address}
												onChange={handleInputChange}
											/>
										</Grid>
										<Grid item xs={3}>
											<TWControls.Input
												size="small"
												name="apn"
												label="APN"
												value={values.apn}
												onChange={handleInputChange}
											/>
										</Grid>
										<Grid item xs={3}>
											<TWControls.Input
												size="small"
												name="phoneNumber"
												label="Phone Number"
												value={values.phoneNumber}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.Input
												size="small"
												name="city"
												label="City"
												value={values.city}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.Input
												size="small"
												name="stateProvince"
												label="State/Prov"
												value={values.stateProvince}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.Input
												size="small"
												name="postalCode"
												label="Postal Code"
												value={values.postalCode}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.Input
												size="small"
												name="country"
												label="Country"
												value={values.country}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.Input
												size="small"
												name="latitude"
												label="Latitude"
												value={values.latitude}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.Input
												size="small"
												name="longitude"
												label="Longitude"
												value={values.longitude}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.Input
												size="small"
												name="totalSqft"
												label="SQFT"
												value={values.totalSqft}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.Input
												size="small"
												name="estimatedSales"
												label="Sales (est)"
												value={values.estimatedSales}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.CheckBox
												size="small"
												name="isActive"
												label="Active?"
												value={values.isActive}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.CheckBox
												size="small"
												name="isInFleet"
												label="Fleet Location?"
												value={values.isInFleet}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.CheckBox
												size="small"
												name="isPOIOnly"
												label="POI Only?"
												value={values.isPOIOnly}
												onChange={handleInputChange}
											/>
										</Grid>

										<Grid item xs={3}>
											<TWControls.CheckBox
												size="small"
												name="isTenantOnly"
												label="Tenant Only?"
												value={values.isTenantOnly}
												onChange={handleInputChange}
											/>
										</Grid>
									</Grid>
									<div className="AssetEdit__Asset">
										{picLoading ? (
											<Spinner />
										) : values.image ? (
											<img className="AssetEdit__Image" src={values.image} alt="" />
										) : (
											<img className="newLocation__altimage" src={AddImage} alt="" />
										)}
										<FileUploader onChange={info => uploadImage(info)}>
											<FaImage /> {values.image ? `Update Image` : `Add Image`}
										</FileUploader>
									</div>

									<div className="AssetEdit__Actions">
										<Button size="small" onClick={submitHandler}>
											Update
										</Button>
									</div>
								</Card>
							</Form>
						</StyledAssetEdit>
					</Layout.Body>
				</Layout.Page>
			</Layout>
		</>
	)
}

const StyledAssetEdit = styled.div`
	max-width: ${_.rem(960)};
	margin: 0 auto;

	.AssetEdit {
		&__Card {
			h2 {
				overflow: hidden;
				white-space: nowrap;
			}
		}

		&__password-buttton {
			${_.MIXINS.vhc}
			margin: ${_.rem(16)} 0 ${_.rem(24)};
		}

		&__Asset,
		&__Actions {
			${_.MIXINS.mod}
			${_.MIXINS.vhc}
			flex-direction: column;
			padding: ${_.rem(16)};
		}

		&__Image {
			width: ${_.rem(250)};
			margin-bottom: ${_.rem(10)};
			border-radius: ${_.rem(5)};
			border: ${_.rem(2)} solid #0073e6;
			margin-bottom: ${_.rem(16)};
		}
	}

	input {
		font-size: ${_.rem(18)};
	}

	.input-group {
		${({ isMobile }) =>
			!isMobile &&
			`
      display: flex;
      gap: ${_.rem(16)};
    `}
	}

	.input-group-title {
		margin-top: ${_.rem(16)};
		margin-bottom: ${_.rem(16)};
		font-weight: 400;
		font-size: ${_.rem(14)};
		color: ${_.COLORS.gray_500};
	}
`

export default Location_Edit
