import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import MapLocationRGM from 'components/MapLocationRGM'
import { Grid } from '@mui/material'
import { FaRegCircle } from 'react-icons/fa'
import Layout from 'components/Layout/Layout'
import ListAction from 'features/Actions/components/ListAction'
import { useParams } from 'react-router-dom'
import { initializeApiKey } from 'slices/apiKeysSlice'

const LocationMapsScreen = () => {
	const { id } = useParams()
	const [ringRadius, setRingRadius] = useState(0)
	const [allRings, setAllRings] = useState(false)

	const { entity: apiKey } = useSelector(state => state.apiKey)

	const { location, loading, isInitialized } = useSelector(state => {
		return {
			loading: state.locations.isLoading,
			success: state.locations.isInitialized,
			location: state.locations.entities[id],
			isInitialized: state.locations.isInitialized,
		}
	})

	const handleRing1 = () => {
		setRingRadius(1)
		setAllRings(false)
	}

	const handleRing2 = () => {
		setRingRadius(3)
		setAllRings(false)
	}

	const handleRing3 = () => {
		setRingRadius(5)
		setAllRings(false)
	}
	const handleAllRingsRing = () => {
		setRingRadius(0)
		setAllRings(true)
	}

	// TODO: Create skeleton loader
	if (loading || !location || !location.latitude || !location.longitude) return null

	return (
		<Grid container spacing={3}>
			<Grid item xs={9}>
				<MapLocationRGM
					apiKey={apiKey.key}
					asset={location}
					centerPin={[location.latitude, location.longitude]}
					useRings={true}
					ringRadius={ringRadius}
					allRings={allRings}
					markers={null}
					useCenterPin={true}
					zoom={12}
				/>
			</Grid>
			<Grid item xs={3}>
				<Layout.SidebarItem title="Actions" hasNoMaxHeight>
					<ul>
						<li className="actionList__item">
							<ListAction
								handleAction={handleRing1}
								actionLable={'1 Mile Ring'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
						<li className="actionList__item">
							<ListAction
								handleAction={handleRing2}
								actionLable={'3 Mile Ring'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
						<li className="actionList__item">
							<ListAction
								handleAction={handleRing3}
								actionLable={'5 Mile Ring'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
						<li className="actionList__item">
							<ListAction
								handleAction={handleAllRingsRing}
								actionLable={'1/3/5 Mile Rings'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
					</ul>
				</Layout.SidebarItem>
			</Grid>
		</Grid>
	)
}

export default LocationMapsScreen
