import React from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import TWControls from './controls/TWControls'
import { Form, useForm } from './controls/useForm'
import { initializeUsers } from 'slices/usersSlice'
import { inviteNewUser } from 'slices/invitedUserSlice'
import {
	emailInviteExistingPartner,
	emailInviteExistingPartnerCC,
} from 'functions/loginRegisterEmails'
import swal from 'sweetalert'

const UserAddNew = ({ fromUser, userAdd }) => {
	const dispatch = useDispatch()

	const userId = fromUser.id
	const initialFValues = {
		id: 0,
		name: '',
		email: '',
	}
	const password = 'Pa55T3mp@1'

	const validate = () => {
		let temp = { ...errors }
		temp.name = values.name ? '' : 'This field is required.'
		// temp.firstName = values.firstName ? '' : 'This field is required.'
		temp.email = values.email ? '' : 'Email is not valid.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialFValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) {
			const newUser = values
			console.log(`newUser: `, newUser)

			const emailUser = inviteNewUser({ newUser, inviterId: userId })

			debugger

			if (emailUser) {
				const toUser = {
					name: newUser.name,
					firstName: newUser.firstName,
					email: newUser.email,
				}
				swal(
					`Partner Invited!`,
					`Good job. You've invited ${toUser.name} to partner with you. An invitation has been emailed to ${toUser.firstName} to join TradeWind as well as a cc to you.`,
					'success',
				)

				emailInviteExistingPartner(fromUser, toUser)
				emailInviteExistingPartnerCC(fromUser, toUser)
			}
			userAdd(newUser, resetForm)
		}

		// if (validate()) {
		// 	dispatch(addNewUser(values.name, values.email, password))
		// }
	}

	const handleNickName = fullName => {
		const nameParts = fullName.split(' ')
		values.firstName = nameParts[0]
		return values.firstName
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container>
				<Grid item>
					<TWControls.Input
						name="name"
						label="Name"
						value={values.name}
						onChange={handleInputChange}
						error={errors.name}
					/>
					{/* <TWControls.Input
						name="firstName"
						label="First/Nick Name"
						value={
							values.firstName === '' && values.name > ''
								? values.firstName
								: handleNickName(values.name)
						}
						onChange={handleInputChange}
						error={errors.firstName}
					/> */}
					<TWControls.Input
						name="email"
						label="Email"
						value={values.email}
						onChange={handleInputChange}
						error={errors.email}
					/>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Invite" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default UserAddNew
