import React, { useState, useEffect } from 'react'
import { CardGrid, BusinessCard } from 'components/Card'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'components/Popup'
import LocationArchivePopup from 'components/popups/LocationArchivePopup'
import LocationDeletePopup from 'components/popups/LocationDeletePopup'
import { deleteLocation, initializeActiveLocations, patchLocation } from 'slices/locationSlice'

const LocationsCards = ({ locations, userId }) => {
	const dispatch = useDispatch()
	// const userLogin = useSelector(state => state.userLogin)
	// const { userInfo } = userLogin

	const [openArchivePopup, setOpenArchivePopup] = useState(false)
	const [openDeletePopup, setOpenDeletePopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const menuItems = location => [
		{
			title: 'Edit Location',
			link: location.owner === userId && `/locations/${location._id}/edit`,
		},
		{
			title: 'Archive Location',
			callback: () => {
				setPopupTitle(`Archive Location: ${location.assetName}`)
				setRecordForEdit(location)
				setOpenArchivePopup(true)
			},
		},
		{
			title: 'Delete Location',
			callback: () => {
				setPopupTitle(`Delete Location: ${location.assetName}`)
				setRecordForEdit(location)
				setOpenDeletePopup(true)
			},
		},
	]

	const handleArchiveLocation = async newLocation => {
		const locationId = newLocation._id

		await dispatch(
			patchLocation({
				locationId: locationId,
				updates: { isActive: false, updateNote: 'Location Archived' },
			}),
		)

		dispatch(
			initializeActiveLocations({
				locationId: locationId,
			}),
		)

		setOpenArchivePopup(false)
	}

	const handleDeleteLocation = async newLocation => {
		const locationId = newLocation._id

		await dispatch(deleteLocation(locationId))

		dispatch(
			initializeActiveLocations({
				locationId: locationId,
			}),
		)

		setOpenDeletePopup(false)
	}

	return (
		<div>
			<>
				<CardGrid>
					{locations.map((location, index) => (
						<BusinessCard
							key={index}
							image={location.image}
							isCenter={location.assetType === 'location' ? false : true}
							name={location.assetName}
							address1={location.address}
							address2={`${location.city}, ${location.stateProvince}`}
							linkUrl={`/locations/${location._id}`}
							menuItems={menuItems(location)}
							isOwner={location.owner._id === userId ? true : false}
							ownerImage={location.owner.image}
						/>
					))}
				</CardGrid>
			</>

			{openArchivePopup ? (
				<Popup title={popupTitle} openPopup={openArchivePopup} setOpenPopup={setOpenArchivePopup}>
					<LocationArchivePopup archiveLocation={handleArchiveLocation} location={recordForEdit} />
				</Popup>
			) : null}

			{openDeletePopup ? (
				<Popup title={popupTitle} openPopup={openDeletePopup} setOpenPopup={setOpenDeletePopup}>
					<LocationDeletePopup deleteLocation={handleDeleteLocation} location={recordForEdit} />
				</Popup>
			) : null}
		</div>
	)
}

export default LocationsCards
