import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import Layout from 'components/Layout'
import RenderSubRoutes from 'components/RenderSubRoutes'
import SidebarTemplatesAccordian from 'components/Sidebars/SidebarTemplatesAccordian'
import getTemplateRoutes from './getTemplateRoutes'
import routes from 'constants/routes'

const TemplateTask_Show = () => {
	const params = useParams()
	const currentRoute = useLocation()

	const pathArray = currentRoute.pathname.split('/')
	const isPartnerPage = pathArray[pathArray.length - 1] === 'partners'

	const { id } = params
	const subRoutes = getTemplateRoutes(id)

	return (
		<Layout>
			<Layout.SecondaryNav
				links={subRoutes}
				backLink={{ title: 'Templates', link: routes.TEMPLATES }}
			/>
			<Layout.Page>
				<Layout.Sidebar>
					<SidebarTemplatesAccordian />
				</Layout.Sidebar>
				<Layout.Body>
					<RenderSubRoutes routes={subRoutes} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default TemplateTask_Show
