import React, { useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { Avatar } from '@mui/material'
import { Create as CreateIcon } from '@mui/icons-material'
import { updateNote } from 'slices/locationNoteSlice'

function LocationNote({ locationNote }) {
	const dispatch = useDispatch()

	const [input, setInput] = useState('')
	const [imgArr, setImgArr] = useState([])

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const userId = userInfo._id

	const locationNoteCommentHandler = e => {
		e.preventDefault()
		const newComments = [...locationNote.comments, { user: userId, comment: input }]

		const updatedLocationNotes = {
			_id: locationNote._id,
			userid: locationNote.userid._id,
			note: locationNote.note,
			image: locationNote.image,
			video: locationNote.video,
			// document:locationNote.document,
			comments: newComments,
		}

		dispatch(updateNote(updatedLocationNotes))
		setInput('')
	}

	const buildNote = note => {
		let goodStr = ''
		let imgStr = 0
		let imgPosStart = 0
		let imgPosEnd = 0
		let imgURL = ''

		let chopStr = note

		while (chopStr.search('<img') > 0) {
			imgPosStart = chopStr.search('<img')
			goodStr = `${goodStr}${chopStr.substring(0, imgPosStart)}`
			chopStr = chopStr.slice(imgPosStart)
			imgPosEnd = chopStr.search('>')

			imgURL = chopStr.substring(10, imgPosEnd - 1)
			console.log(`imgURL: `, imgURL)
			imgStr = `<div class="note-image" style="background-image:url('${imgURL}')">${chopStr.substring(
				0,
				imgPosEnd + 1,
			)}</div>`
			goodStr = goodStr + imgStr
			chopStr = chopStr.slice(imgPosEnd + 8)
		}

		goodStr = goodStr + chopStr

		return goodStr
	}

	return (
		<div className="note">
			<div className="note__header">
				<Avatar src={locationNote.userid.image} />
				<div className="note__info">
					<h2>{locationNote.userid.name}</h2>
					<p>{locationNote.userid.email}</p>
				</div>
			</div>
			<div className="note__body">
				<div className="sm:col-span-full">
					<p>{parse(buildNote(locationNote.note))}</p>
				</div>
			</div>
			<div className="note__creator">
				<div className="note__date">
					<p>{moment(locationNote.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
				</div>
			</div>

			<div className="feed_input">
				<form>
					<CreateIcon />
					<input
						value={input}
						onChange={e => setInput(e.target.value)}
						type="text"
						placeholder="comment. . ."
					/>
					<button onClick={locationNoteCommentHandler} type="submit"></button>
				</form>
			</div>
			<div>
				{locationNote.comments.length === 0 ? null : (
					<>
						{locationNote.comments.map(comment => (
							<div key={comment._id} className="feed__inputOptions">
								<div className="feed_avatar">
									<Avatar src={comment.user.image}></Avatar>
								</div>
								<div className="feed_comment">
									<>{comment.comment}</>
								</div>
							</div>
						))}
					</>
				)}
			</div>
		</div>
	)
}

export default LocationNote
