import styled from 'styled-components'
import type { Props } from './Header'
import * as _ from 'styles/helpers'
import '@reach/menu-button/styles.css' // reach-ui

const StyledHeader = styled.div<Partial<Props>>`
	${_.MIXINS.vc};

	@media ${_.MQ.before_nav} {
		min-height: ${_.rem(_.HEADER_HEIGHT_MOBILE)};
	}

	min-height: ${_.rem(_.HEADER_HEIGHT)};

	.Header {
		position: relative;
		z-index: 9999;

		&__Container {
			@media ${_.MQ.before_nav} {
				padding: ${_.rem(16)};
				padding-top: ${({ isOpen }) => isOpen && _.rem(_.HEADER_HEIGHT_MOBILE)};
				height: ${({ isOpen }) => isOpen && '100vh'};
			}

			@media ${_.MQ.nav} {
				display: flex;
			}
		}

		&__logo {
			width: ${_.rem(120)};

			&-wrapper {
				${_.MIXINS.vc}
			}
		}
	}

	.dropdown {
		margin-left: auto;

		@media ${_.MQ.before_nav} {
			height: 100%;
		}

		ul {
			${_.MIXINS.ul_reset};
			flex: 1;

			@media ${_.MQ.nav} {
				${_.MIXINS.vhc};
				height: 100%;
			}
		}

		// .nav-link
		&-link {
			text-decoration: none;
			color: ${_.COLORS.rich_black};
			opacity: 0.4;
			/* for buttons */
			margin: 0;
			background: none;
			border: none;
			cursor: pointer;
			font-size: inherit;
			font-family: inherit;

			@media ${_.MQ.before_nav} {
				${_.MIXINS.vc};
				padding: ${_.rem(12)};

				&-icon {
					font-size: ${_.rem(32)};
					margin-right: ${_.rem(16)};
				}
			}

			@media ${_.MQ.nav} {
				${_.MIXINS.vhc};
				flex-direction: column;
				min-width: ${_.rem(90)};
				padding: ${_.rem(2)} ${_.rem(16)};

				&-icon {
					font-size: ${_.rem(28)};
					margin-bottom: ${_.rem(2)};
				}
			}

			&-label {
				${_.FONTS.bold};
				display: flex;

				&-icon {
					margin-left: ${_.rem(2)};
				}

				@media ${_.MQ.before_nav} {
					font-size: ${_.rem(24)};
					font-weight: bold;
				}

				@media ${_.MQ.nav} {
					font-size: ${_.rem(12)};
				}
			}

			&:hover,
			&:focus,
			&.is-active {
				opacity: 1;
				color: ${_.COLORS.tradewind_blue_a11y};
			}
		}
	}

	.nav {
		margin-left: auto;

		@media ${_.MQ.before_nav} {
			height: 100%;
		}

		ul {
			${_.MIXINS.ul_reset};
			flex: 1;

			@media ${_.MQ.nav} {
				${_.MIXINS.vhc};
				height: 100%;
			}
		}

		// .nav-link
		&-link {
			text-decoration: none;
			color: ${_.COLORS.rich_black};
			opacity: 0.4;
			/* for buttons */
			margin: 0;
			background: none;
			border: none;
			cursor: pointer;
			font-size: inherit;
			font-family: inherit;

			@media ${_.MQ.before_nav} {
				${_.MIXINS.vc};
				padding: ${_.rem(12)};

				&-icon {
					font-size: ${_.rem(32)};
					margin-right: ${_.rem(16)};
				}
			}

			@media ${_.MQ.nav} {
				${_.MIXINS.vhc};
				flex-direction: column;
				min-width: ${_.rem(90)};
				padding: ${_.rem(2)} ${_.rem(16)};

				&-icon {
					font-size: ${_.rem(28)};
					margin-bottom: ${_.rem(2)};
				}
			}

			&-label {
				${_.FONTS.bold};
				display: flex;

				&-icon {
					margin-left: ${_.rem(2)};
				}

				@media ${_.MQ.before_nav} {
					font-size: ${_.rem(24)};
					font-weight: bold;
				}

				@media ${_.MQ.nav} {
					font-size: ${_.rem(12)};
				}
			}

			&:hover,
			&:focus,
			&.is-active {
				opacity: 1;
				color: ${_.COLORS.tradewind_blue_a11y};
			}

			&.is-disabled {
				pointer-events: none;
				opacity: 0.33;
			}
		}
	}

	.user-menu {
		${_.MIXINS.vc};
		position: relative;

		.nav-link {
			opacity: 1;
		}
		.dropdown-link {
			opacity: 1;
			color: ${_.COLORS.tradewind_blue_a11y};
		}
	}

	.mobile-menu {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: ${_.rem(60)};
		display: flex;
		justify-content: space-between;
		padding-left: ${_.rem(16)};

		// .mobile-menu-button
		&-button,
		&-button:focus {
			color: ${_.COLORS.foreground};
		}

		.nav-link {
			opacity: 1;
			height: 100%;
			font-size: ${_.rem(32)};
		}
	}

	hr {
		border: none;
		height: 1px;
		background-color: ${_.COLORS.gray_200};
	}

	/* reach-ui overrides */
	[data-reach-menu-popover] {
		position: absolute;
		top: 100%;
		right: 0;
		/* margin-top: ${_.rem(-8)}; */
		z-index: 9999;
	}

	[data-reach-menu-list],
	[data-reach-menu-items] {
		min-width: ${_.rem(160)};
		padding: ${_.rem(12)} 0;
		border: solid ${_.rem(1)} rgba(0, 0, 0, 0.1);
		box-shadow: 0 ${_.rem(4)} ${_.rem(8)} ${_.rem(-2)} rgba(0, 0, 0, 0.2);
		border-radius: ${_.rem(4)};
	}
	[data-reach-menu-item] {
		color: ${_.COLORS.rich_black};

		&[data-selected] {
			color: ${_.COLORS.white};
			background: ${_.COLORS.tradewind_blue_a11y};
		}
	}
`

export default StyledHeader
