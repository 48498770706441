import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import Layout from 'components/Layout'
import Popup from 'components/Popup'
import LocationEditPopup from 'components/popups/LocationEditPopup'
import MapLocationPopup from 'components/popups/MapLocationPopup'
import { initializeApiKey } from 'slices/apiKeysSlice'
import { initializeLocations, updateLocation } from 'slices/locationSlice'

const SidebarLocationTop = ({ location, originator }) => {
	const dispatch = useDispatch()

	const [openPopup, setOpenPopup] = useState(false)
	const [openMap, setOpenMap] = useState(false)
	const [recordForEdit, setRecordForEdit] = useState(null)
	const [center, setCenter] = useState([])
	const [mapTitle, setMapTitle] = useState(null)
	const [popupTitle, setPopupTitle] = useState(null)

	const { entity: apiKey } = useSelector(state => state.apiKey)

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const locationId = location._id

	useEffect(() => {
		dispatch(initializeApiKey('REACT_APP_GOOGLE_API_KEY'))
	}, [location, locationId, dispatch])

	const editLocation = async values => {
		const newLocation = values
		const postalCode = values.postalCode.substring(0, 5)
		const params = {
			params: {
				postalCode: postalCode,
			},
		}

		let c = ''
		try {
			let { data } = await axios.get(`/api/postalcodedemographics/postalcodecbsa`, params)
			c = data
		} catch (e) {
			console.error(e)
		}

		const locationType = values.locationType
		switch (locationType) {
			case 'Commercial Center':
				newLocation.assetType = 'center'
				break

			case 'Commercial Location':
				newLocation.assetType = 'location'
				break

			case 'Retail Center':
				newLocation.assetType = 'center'
				break

			case 'Retail Location':
				newLocation.assetType = 'location'
				break

			default:
				newLocation.assetType = 'location'
				break
		}

		newLocation.updateNote = 'Edited Location.'
		await dispatch(updateLocation(newLocation))
		await dispatch(initializeLocations())

		setOpenPopup(false)
	}

	const openEdit = location => {
		setRecordForEdit(location)
		setPopupTitle('Edit Location')
		setOpenPopup(true)
	}

	const openInMap = location => {
		setCenter([location.latitude, location.longitude])
		setRecordForEdit(location)

		if (location.retailer) {
			setMapTitle(
				`${location.retailer}` +
					` - ` +
					`${location.assetName}` +
					` - ` +
					`${location.address}` +
					`, ` +
					`${location.city}` +
					`, ` +
					`${location.stateProvince}`,
			)
		}

		setMapTitle(
			`${location.assetName}` +
				` - ` +
				`${location.address}` +
				`, ` +
				`${location.city}` +
				`, ` +
				`${location.stateProvince}`,
		)

		setOpenMap(true)
	}

	return (
		<div>
			<Layout.SidebarAssetTop
				image={location.image}
				assetName={
					location.owner._id === userInfo._id ? (
						<span className="title">
							{location.assetName}{' '}
							<IconButton color="primary" size="small" onClick={() => openEdit(location)}>
								<EditIcon fontSize="inherit" />
							</IconButton>
						</span>
					) : (
						<span className="title">{location.assetName} </span>
					)
				}
				address={
					<span onClick={() => openInMap(location)}>
						<Link to="#">
							{location.address} {location.city}, {location.stateProvince}
						</Link>
					</span>
				}
				webAddress={
					location.assetLink && (
						<span>
							<a target="_blank" rel="noopener noreferrer" href={location.assetLink}>
								{location.assetLink}
							</a>
						</span>
					)
				}
				originator={`${originator}${location.owner.name}`}
			/>
			{openMap ? (
				<Popup title={mapTitle} openPopup={openMap} setOpenPopup={setOpenMap}>
					<MapLocationPopup apiKey={apiKey.key} location={recordForEdit} center={center} />
				</Popup>
			) : null}
			{openPopup ? (
				<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
					<LocationEditPopup editLocation={editLocation} recordForEdit={recordForEdit} />
				</Popup>
			) : null}
		</div>
	)
}

export default SidebarLocationTop
