import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'components/controls/useForm'
import { useDispatch, useSelector } from 'react-redux'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import TWControls from 'components/controls/TWControls'
import { Grid } from '@mui/material'
import { updateUser } from 'slices/authSlice'
import { PersonAddOutlined as PersonAddOutlinedIcon } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
	pageContent: {
		margin: theme.spacing(3),
		padding: theme.spacing(3),
		display: 'flex',
		width: '100%',
	},
	newButton: {
		position: 'absolute',
		right: '10px',
	},
}))

const RegisterConfirm = ({ history, location }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const queryParams = new URLSearchParams(window.location.search)

	const id = queryParams.get('id')
	const [name, setName] = useState(queryParams.get('name'))
	const [firstName, setFirstName] = useState(queryParams.get('firstname'))
	const [email, setEmail] = useState(queryParams.get('email'))
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [message, setMessage] = useState(null)

	const userRegister = useSelector(state => state.userRegister)
	const { loading, error, userInfo } = userRegister

	const redirect = location.search ? location.search.split('=')[1] : '/'

	useEffect(() => {
		// if (userInfo) {
		// 	history.push(redirect)
		// }
	}, [history, userInfo, redirect])

	const submitHandler = e => {
		e.preventDefault()
		// DISPATCH REGISTER
		if (password !== confirmPassword) {
			setMessage('Passwords do not match')
		} else {
			const user = {
				_id: id,
				name,
				firstName,
				email,
				password,
			}
			dispatch(updateUser(user))
		}
	}

	return (
		<div>
			<Grid container style={{ display: 'flex', justifyContent: 'center' }}>
				<div>
					<div>
						<div className="feed_inputContainer">
							<div>
								{loading ? (
									<Spinner />
								) : error ? (
									<Message variant="danger">{error}</Message>
								) : (
									<h3>New User Registration</h3>
								)}
							</div>
							<div className="text-right">
								<TWControls.Button
									className={classes.newButton}
									variant="outlined"
									text="Register"
									size="medium"
									startIcon={<PersonAddOutlinedIcon />}
									onClick={submitHandler}
								/>
							</div>
						</div>
						<div className="feed_inputContainer">
							<Form onSubmit={submitHandler}>
								<Grid item>
									<TWControls.Input
										name="name"
										label="Full Name"
										type="text"
										value={name}
										onChange={e => setName(e.target.value)}
									/>
									<TWControls.Input
										name="firstName"
										label="First/Greeting Name"
										type="text"
										value={firstName}
										onChange={e => setFirstName(e.target.value)}
									/>
									<TWControls.Input
										name="email"
										label="Email Address"
										type="text"
										value={email}
										onChange={e => setEmail(e.target.value)}
									/>
									<TWControls.Input
										name="password"
										label="Password"
										type="password"
										autoComplete="new-password"
										value={password}
										onChange={e => setPassword(e.target.value)}
									/>
									<TWControls.Input
										name="confirmPassword"
										label="Confirm Password"
										type="password"
										value={confirmPassword}
										onChange={e => setConfirmPassword(e.target.value)}
									/>
								</Grid>
							</Form>
						</div>

						<div className="py-3">
							<div>
								Have an Account?{' '}
								<Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>Login</Link>
							</div>
						</div>
					</div>
				</div>
			</Grid>
		</div>
	)
}

export default RegisterConfirm
