import PortfolioLocations from './PortfolioLocations' // _OLD
import PortfolioNotes from './PortfolioNotes'
import PortfolioTasks from './PortfolioTasks'
import PortfolioMaps from './PortfolioMaps'
import PortfolioImages from './PortfolioImages'
import PortfolioFiles from './PortfolioFiles'
import PortfolioPartners from './PortfolioPartners'
import PortfolioAddPartners from './PortfolioAddPartners'
import Portfolios_Edit from './Portfolios_Edit'
import PortfolioAddLocations from './PortfolioAddLocations'
import routes from 'constants/routes'

const getPortfolioRoutes = (id: string) => [
	{
		linkText: 'Locations',
		linkTo: `${routes.PORTFOLIOS}/${id}/locations`,
		linkPattern: `${routes.PORTFOLIOS}/:id/locations`,
		component: PortfolioLocations,
	},
	{
		linkText: 'Area',
		linkTo: `${routes.PORTFOLIOS}/${id}/maps`,
		linkPattern: `${routes.PORTFOLIOS}/:id/maps`,
		component: PortfolioMaps,
	},
	{
		linkText: 'Notes',
		linkTo: `${routes.PORTFOLIOS}/${id}/notes`,
		linkPattern: `${routes.PORTFOLIOS}/:id/notes`,
		component: PortfolioNotes,
	},
	{
		linkText: 'Tasks',
		linkTo: `${routes.PORTFOLIOS}/${id}/tasks`,
		linkPattern: `${routes.PORTFOLIOS}/:id/tasks`,
		component: PortfolioTasks,
	},
	{
		linkText: 'Images',
		linkTo: `${routes.PORTFOLIOS}/${id}/images`,
		linkPattern: `${routes.PORTFOLIOS}/:id/images`,
		component: PortfolioImages,
	},
	{
		linkText: 'Documents',
		linkTo: `${routes.PORTFOLIOS}/${id}/files`,
		linkPattern: `${routes.PORTFOLIOS}/:id/files`,
		component: PortfolioFiles,
	},
	{
		linkText: 'Partners',
		linkTo: `${routes.PORTFOLIOS}/${id}/partners`,
		linkPattern: `${routes.PORTFOLIOS}/:id/partners`,
		component: PortfolioPartners,
	},
	/*
		Removing linkText and/or linkTo removes link from sub nav but creates a route
	*/
	{
		linkPattern: `${routes.PORTFOLIOS}/:id/edit`,
		component: Portfolios_Edit,
	},
	{
		linkPattern: `${routes.PORTFOLIOS}/:id/addpartners`,
		component: PortfolioAddPartners,
	},
	{
		linkPattern: `${routes.PORTFOLIOS}/:id/addlocations`,
		component: PortfolioAddLocations,
	},
	/*
		Redirect routes
	*/
	{
		redirectFrom: `${routes.PORTFOLIOS}/:id`,
		redirectTo: `${routes.PORTFOLIOS}/${id}/locations`,
	},
]

export default getPortfolioRoutes
