import ArchivedLocationsLibrary from '../contentPages/ArchivedLocationsLibrary'
import BulkLoadLocations from '../contentPages/BulkLoadLocations'
import LocationArchiveMap from '../navPages/LocationArchiveMap'
import LocationBulkLoadMaps from '../navPages/LocationBulkLoadMaps'
import routes from 'constants/routes'

const getLocationAdminToolRoutes = (param: 'archive' | 'bulkload') => {
	if (param === 'archive') {
		return [
			{
				linkText: 'Archive ',
				linkTo: `${routes.LOCATION_ADMIN_TOOLS}/tools/${param}/table`,
				linkPattern: `${routes.LOCATION_ADMIN_TOOLS}/tools/${param}/table`,
				component: ArchivedLocationsLibrary,
			},
			{
				linkText: 'Map',
				linkTo: `${routes.LOCATION_ADMIN_TOOLS}/tools/${param}/map`,
				linkPattern: `${routes.LOCATION_ADMIN_TOOLS}/tools/:param/map`,
				component: LocationArchiveMap,
			},
			{
				redirectFrom: `${routes.LOCATION_ADMIN_TOOLS}/tools/${param}`,
				redirectTo: `${routes.LOCATION_ADMIN_TOOLS}/tools/${param}/table`,
			},
		]
	}

	if (param === 'bulkload') {
		return [
			{
				linkText: 'Bulk Load ',
				linkTo: `${routes.LOCATION_ADMIN_TOOLS}/tools/${param}/table`,
				linkPattern: `${routes.LOCATION_ADMIN_TOOLS}/tools/${param}/table`,
				component: BulkLoadLocations,
			},
			{
				linkText: 'Map',
				linkTo: `${routes.LOCATION_ADMIN_TOOLS}/tools/${param}/map`,
				linkPattern: `${routes.LOCATION_ADMIN_TOOLS}/tools/:param/map`,
				component: LocationBulkLoadMaps,
			},
			{
				redirectFrom: `${routes.LOCATION_ADMIN_TOOLS}/tools/${param}`,
				redirectTo: `${routes.LOCATION_ADMIN_TOOLS}/tools/${param}/table`,
			},
		]
	}
}
export default getLocationAdminToolRoutes
