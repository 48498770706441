import React, { useState } from 'react'
import Layout from 'components/Layout'
import PartnerApps from './components/PartnerApps'
import SidebarMyPartnerApps from './components/SidebarMyPartnerApps'

const PartnerApps_Index = () => {
	const [openPopup, setOpenPopup] = useState(false)
	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<SidebarMyPartnerApps />
				</Layout.Sidebar>
				<Layout.Body>
					<Layout.PageHeader>
						<h2>Apps</h2>
					</Layout.PageHeader>
					<PartnerApps openPopup={openPopup} setOpenPopup={setOpenPopup} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default PartnerApps_Index
