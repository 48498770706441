import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
const PORTFOLIO_NOTE = 'portfolio_note'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
	error: null,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const getPortfolioNotes = createAsyncThunk(
	`${PORTFOLIO_NOTE}/getPortfolioNotes`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { portfolioId } = payload
		console.log(`portfolioid: `, portfolioId)

		try {
			const { data } = await axios.get(`/api/portfolioNotes/notes`, {
				params: {
					portfolioid: portfolioId,
				},
			})
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Failed to get notes')
		}
	},
)

export const createPortfolioNote = createAsyncThunk(
	`${PORTFOLIO_NOTE}/createPortfolioNote`,
	async (portfolioNote, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/portfolioNotes`, portfolioNote, prepConfig(getState))
			// const { data } = await axios.post(`/api/portfolioNotes`, note, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to get notes')
		}
	},
)

export const updatePortfolioNote = createAsyncThunk(
	`${PORTFOLIO_NOTE}/updatePortfolioNote`,
	async (note: any, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.put(
				`/api/portfolioNotes/${note._id}`,
				note,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to create center')
		}
	},
)

const portfolioNoteSlice = createSlice({
	name: 'portfolio',
	initialState,
	reducers: {},
	extraReducers: {
		[getPortfolioNotes.fulfilled as any]: (state, action) => {
			const notes = action.payload

			state.ids = notes?.map((note, index) => {
				state.entities[note._id] = note
				return note._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[getPortfolioNotes.pending as any]: state => {
			state.isLoading = true
		},
		[getPortfolioNotes.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
		},

		[createPortfolioNote.fulfilled as any]: (state, action) => {
			const newNote = action.payload
			state.entities[newNote._id] = newNote
		},
		[updatePortfolioNote.fulfilled as any]: (state, action) => {
			const updatedNote = action.payload
			state.entities[updatedNote._id] = updatedNote
		},
	} as any,
})

export default portfolioNoteSlice

// export const { } = portfoliosSlice.actions;
