import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { initializeMyPartners } from 'slices/myPartnersSlice'
import Layout from 'components/Layout'
import { getLocationByID, removePartnerFromLocation } from 'slices/locationSlice'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
	inline: {
		display: 'inline',
	},
}))

const SidebarAddPartner = ({ history, match }) => {
	const { id: locationId } = useParams()
	const classes = useStyles()

	const dispatch = useDispatch()

	const { success, location } = useSelector(state => {
		return {
			isLoading: state.locations.isLoading,
			success: state.locations.isInitialized,
			location: state.locations.entities[locationId],
		}
	})
	const partners = location.partners

	useEffect(() => {
		if (!location || location._id !== locationId) {
			dispatch(getLocationByID(locationId))
		}
		dispatch(initializeMyPartners())
	}, [location, locationId, dispatch])

	const dropPartnerFromLocation = id => {
		dispatch(removePartnerFromLocation({ locationId, partnerId: id }))
	}

	const isEmpty = partners?.length === 0

	return (
		<Layout.SidebarItem title="Partners" isEmpty={isEmpty}>
			{success ? (
				<div className="sidebar__addDrop">
					<List dense>
						{partners.map(partner => (
							<ListItem className="listGroup__task" key={partner._id}>
								<ListItemAvatar>
									<Avatar src={partner.partner.image} alt={partner.partner.image} />
								</ListItemAvatar>
								<ListItemText
									primary={
										<a
											href={`mailto: ${partner.partner.email}?subject=${location.assetName}`}
											className="feed__collaboratorName"
										>
											{partner.partner.name}
										</a>
									}
									secondary={
										<React.Fragment>
											<Typography
												component="span"
												variant="body2"
												className={classes.inline}
												color="textPrimary"
											>
												{partner.partner.title}
												{' - '}
											</Typography>
											{partner.partner.company}
										</React.Fragment>
									}
								/>
								<ListItemAvatar
									color="secondary"
									size="small"
									onClick={() => dropPartnerFromLocation(partner.partner)}
								>
									<DeleteIcon />
								</ListItemAvatar>
							</ListItem>
						))}
					</List>
				</div>
			) : null}
		</Layout.SidebarItem>
	)
}

export default SidebarAddPartner
