import React, { useEffect } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import RenderSubRoutes from 'components/RenderSubRoutes'
import SidebarLocationTop from './sidebars/SidebarLocationTop'
import SidebarLocationProjectPartners from './sidebars/SidebarLocationProjectPartners'
import getLocationProjectRoutes from './getRoutes/getLocationProjectRoutes'
import getCenterProjectRoutes from './getRoutes/getCenterProjectRoutes'
import Layout from 'components/Layout'
import { BusinessCard } from 'components/Card'
import routes from 'constants/routes'
import useMediaQuery from 'hooks/useMediaQuery'
import * as _ from 'styles/helpers'
import { getLocationByID } from 'slices/locationSlice'
import { initializeUsers } from 'slices/usersSlice'
import { initializeProjects } from 'slices/projectSlice'
import getLocationProjectPartnerRoutes from './getRoutes/getLocationProjectPartnerRoutes'

const Locations_Project_Show = () => {
	const dispatch = useDispatch()
	const params = useParams()
	const { id, projectId } = params
	const locationId = id

	const currentRoute = useLocation()

	const pathArray = currentRoute.pathname.split('/')
	const isPartnerPage = pathArray[pathArray.length - 1] === 'partners'

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const { isLoading, isInitialized, location } = useSelector(state => {
		return {
			isLoading: state.locations.isLoading,
			isInitialized: state.locations.isInitialized,
			location: state.locations.entities[id],
		}
	})

	const { projectLoading, projectInitialized, project } = useSelector(state => {
		return {
			projectLoading: state.projects.isLoading,
			projectInitialized: state.projects.isInitialized,
			project: state.projects.entities[projectId],
		}
	})

	const { success: userListInitialized } = useSelector(state => {
		return {
			success: state.userList.isInitialized,
		}
	})

	useEffect(() => {
		if (!projectInitialized) {
			dispatch(initializeProjects({ locationId: id }))
		}
		if (!isInitialized) {
			dispatch(initializeUsers())
			dispatch(getLocationByID(id))
		}
	}, [dispatch, id, isInitialized, projectInitialized, userListInitialized])

	const partners = project?.partners
	const assetOwner = project?.owner
	const isMobile = useMediaQuery(_.MQ.before_nav)

	if (!location || isLoading) {
		return null
	}

	if (!project) {
		return null
	}

	// console.log(`assetOwner: `, assetOwner)
	// console.log(`userInfo._id: `, userInfo._id)
	var subRoutes = []
	if (location.assetType === 'center') {
		subRoutes = getCenterProjectRoutes(id, projectId)
	} else {
		if (assetOwner._id === userInfo._id) {
			subRoutes = getLocationProjectRoutes(id, projectId)
		} else {
			subRoutes = getLocationProjectPartnerRoutes(id, projectId)
		}
	}

	return (
		<Layout>
			<Layout.SecondaryNav
				links={subRoutes}
				backLink={{
					title: `${location.assetName}`,
					link: `${routes.LOCATIONS}/${id}/projects`,
				}}
			/>
			<Layout.Page>
				<Layout.Sidebar isOffset>
					{location ? (
						<>
							<SidebarLocationTop location={location} originator={`Project Originator:  `} />
							<SidebarLocationProjectPartners
								assetOwner={assetOwner}
								assetName={location?.assetName}
								projectPartners={partners}
								userId={userInfo._id}
							/>
						</>
					) : null}
				</Layout.Sidebar>
				<Layout.Body>
					{isMobile && isLoading === false && (
						<div style={{ marginBottom: '24px' }}>
							<BusinessCard
								isHorizontal
								image={location?.image}
								name={location?.assetName}
								address1={location?.address}
								address2={`${location?.city}, ${location?.stateProvince}`}
							/>
						</div>
					)}
					<RenderSubRoutes routes={subRoutes} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Locations_Project_Show
