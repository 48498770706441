import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initializeMyPartners } from 'slices/myPartnersSlice'
import Layout from 'components/Layout'

function SidebareMyPartners() {
	const dispatch = useDispatch()

	const {
		entities: myPartners,
		ids: myPartnerIds,
		isLoading: myPartnersLoading,
		isInitialized: myPartnersInitialized,
	} = useSelector(state => state.myPartners)
	const myPartnersList = myPartnerIds.map(id => myPartners[id])

	useEffect(() => {
		dispatch(initializeMyPartners())
	}, [dispatch])

	const isEmpty = myPartnersList?.length === 0

	return (
		<Layout.SidebarItem title="My Partners" isEmpty={isEmpty} hasNoMaxHeight>
			{myPartnersInitialized ? (
				<ul>
					{myPartnersList.map(user => (
						<li className="listGroup__task" key={user._id}>
							{user.isAccepted ? (
								<Layout.PartnerItem
									avatar={user.partner.image}
									name={user.partner.name}
									// title={user.title}
									company={user.partner.company}
									link={`mailto: ${user.partner.name} (${user.partner.email})`}
									isOwner={user.owner}
								/>
							) : (
								<Layout.PartnerItem
									avatar={user.partner.image}
									name={user.partner.name}
									title={user.partner.company}
									company="(pending)"
									link={`mailto: ${user.partner.name} (${user.partner.email})`}
									isOwner={user.owner}
								/>
							)}
						</li>
					))}
				</ul>
			) : null}
		</Layout.SidebarItem>
	)
}

export default SidebareMyPartners
