import React from 'react'
import { useSelector } from 'react-redux'
import Layout from 'components/Layout'
import PortfolioBlank from 'images/Portfolio_Blank.png'
import AvatarBack from 'images/Portfolio.png'

function PortfolioSidebarTop({ portfolio }) {
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	return (
		<Layout.SidebarTop
			img={portfolio.image ? portfolio.image : PortfolioBlank}
			title={portfolio.assetName}
			link={portfolio.owner._id === userInfo._id && `/portfolios/${portfolio._id}/edit`}
		>
			<p>{portfolio.description}</p>
		</Layout.SidebarTop>
	)
}

export default PortfolioSidebarTop
