import React, { useState } from 'react'
import SidebarTemplatesAccordian from 'components/Sidebars/SidebarTemplatesAccordian'
import Layout from 'components/Layout'
import Templates from './components/Templates/Templates'

const Templates_Index = () => {
	const [openPopup, setOpenPopup] = useState(false)

	return (
		<div>
			<Layout>
				<Layout.Page>
					<Layout.Sidebar>
						<SidebarTemplatesAccordian />
					</Layout.Sidebar>
					<Layout.Body>
						<Layout.PageHeader>
							<h2>Templates</h2>
						</Layout.PageHeader>
						<Templates openPopup={openPopup} setOpenPopup={setOpenPopup} />
					</Layout.Body>
				</Layout.Page>
			</Layout>
		</div>
	)
}

export default Templates_Index
