import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, IconButton } from '@mui/material'
import { Create as CreateIcon, ThumbUpAltOutlined as LikeIcon } from '@mui/icons-material'
import { updatePost } from 'slices/postSlice'

function Post({ post }) {
	const dispatch = useDispatch()

	const [input, setInput] = useState('')
	const [likes, setLikes] = useState(post.likes.length)

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	// const userId = userInfo._id

	const likeMyPostHandler = post => {
		const existsLike = post.likes.find(x => x === userInfo._id)
		let newLikes = []

		if (existsLike) newLikes = post.likes.filter(item => item !== userInfo._id)
		else newLikes = [...post.likes, userInfo._id]

		setLikes(newLikes.length)
		dispatch(updatePost({ ...post, likes: newLikes }))
	}

	const postCommentHandler = (e, post) => {
		e.preventDefault()
		setInput('') // clear comment input

		const newComments = [...post.comments, { user: userInfo._id, comment: input }]

		const updatedPosts = {
			...post,
			comments: newComments,
		}

		dispatch(updatePost(updatedPosts))
	}

	return (
		<div className="post">
			<div className="post__header">
				<Avatar src={post.user.image}>{post.user.name}</Avatar>
				<div className="post__info">
					<h2>{post.user.name}</h2>
					<p>{post.user.email}</p>
				</div>
			</div>
			<div className="post__body">
				<p>{post.message}</p>
			</div>
			<br />

			<div className="post__image">
				{post.video && (
					<div className="post__image-video">
						<video src={post.video} controls />
					</div>
				)}
				{post.image && (
					<div className="post__image-img" style={{ backgroundImage: `url(${post.image})` }} />
				)}
			</div>

			<div className="post_actions">
				<div className="post__buttons">
					<label>
						<IconButton color="primary" component="span" onClick={() => likeMyPostHandler(post)}>
							<LikeIcon />
						</IconButton>
						{likes > 0 ? `${likes}` : null}
					</label>
				</div>
			</div>

			{post.comments.length === 0 ? null : (
				<div className="post__comments">
					{post.comments.map(comment => (
						<div key={comment._id} className="feed__inputOptions">
							<div className="feed_avatar">
								<Avatar src={comment.user.image}></Avatar>
							</div>
							<div className="feed_comment">{comment.comment}</div>
						</div>
					))}
				</div>
			)}

			<div className="feed_input">
				<form>
					<CreateIcon />
					<input
						value={input}
						onChange={e => setInput(e.target.value)}
						type="text"
						placeholder="Comment"
					/>
					<button onClick={e => postCommentHandler(e, post)} type="submit"></button>
				</form>
			</div>
		</div>
	)
}

export default Post
