import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Avatar, Grid } from '@mui/material'
import Layout from 'components/Layout'
import Card from 'components/Card'
import styled from 'styled-components'
import * as _ from 'styles/helpers'
import SuccessImage from 'images/success.jpg'

function cardData(cardLink, cardLable, cardNote, cardP2, cardCol) {
	return { cardLink, cardLable, cardNote, cardP2, cardCol }
}

const cardRows = [
	cardData(
		'/locationadmintools/tools/archive',
		'Archived Locations',
		'Re-activate previously Archived Locations.',
		'',
		1,
	),
	cardData(
		'/locationadmintools/tools/bulkload',
		'Bulk Load Locations',
		'Upload a list of locations.',
		'(File Types: .xls, .xlsx, .csv)',
		2,
	),
	cardData('', 'A3', 'Future Feature!', '', 3),
	cardData('', 'A4', 'Future Feature!', '', 4),
	cardData('', 'B1', 'Future Feature!', '', 1),
	cardData('', 'B2', 'Future Feature!', '', 2),
	cardData('', 'B3', 'Future Feature!', '', 3),
	cardData('', 'B4', 'Future Feature!', '', 4),
]

const LocationAdminTools = () => {
	const userLogin = useSelector((state: any) => state.userLogin)
	const { userInfo: user } = userLogin
	const userId = user._id

	return (
		<React.Fragment>
			<Layout>
				<Layout.Page>
					<Layout.Body>
						<StyledDashboard>
							<div className="IntroContent">
								{cardRows.map((cardRow, index) => (
									<Card className={`IntroContent__card IntroContent__card--col-${cardRow.cardCol}`}>
										<Link to={cardRow.cardLink} style={{ textDecoration: 'none' }}>
											<div className="IntroContent__card-header">
												{!cardRow.cardLink && (
													<Avatar src={SuccessImage} className="IntroContent__card-header-avatar" />
												)}{' '}
												<h2>{cardRow.cardLable}</h2>
											</div>
											<div className="IntroContent__card-body">
												<p>{cardRow.cardNote}</p>
												<p>{cardRow.cardP2 && cardRow.cardP2}</p>
											</div>
										</Link>
									</Card>
								))}{' '}
							</div>
						</StyledDashboard>
					</Layout.Body>
				</Layout.Page>
			</Layout>
		</React.Fragment>
	)
}

const StyledDashboard = styled.div`
	.IntroContent {
		display: grid;
		grid-gap: ${_.rem(24)};
		grid-template-columns: repeat(12, [col] 1fr);

		&__card {
			cursor: pointer;
			border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};

			&--disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}

		&__card_intro-header {
			${_.MIXINS.vc};
			justify-content: center;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(36)};
				font-weight: 600;
				color: ${_.COLORS.blue_700};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(18)};
				font-style: italic;
			}
		}
		&__card-header {
			${_.MIXINS.vc};
			justify-content: center;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				padding-top: ${_.rem(8)};
				font-size: ${_.rem(20)};
				font-weight: 600;
				color: ${_.COLORS.tradewind_blue};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			h4 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				text-align: center;
				padding-top: ${_.rem(8)};
				font-size: ${_.rem(16)};
				font-weight: 500;
				color: black;
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				text-align: center;
				margin: 0;
				font-size: ${_.rem(20)};
				font-weight: 500;
				color: ${_.COLORS.blue_700};
			}
		}

		&__card-avatar {
			text-align: center;
			height: 100%;
			max-width: ${_.rem(560)};
		}

		&__card-avatar-success {
			/* display: flex;
			flex-direction: column; */
			align-items: center;
			/* justify-content: center; */
			text-align: center;
			height: 75%;
			padding-left: ${_.rem(60)};
			/* max-width: ${_.rem(260)}; */
		}

		&__card-header-avatar {
			align-items: center;
			text-align: center;
			height: 100%;
			max-width: ${_.rem(60)};
		}

		&__card-body {
			padding-top: ${_.rem(8)};
			text-align: center;
			height: 100%;
			color: ${_.COLORS.rich_black};
		}

		&__card-steps {
			text-align: center;
			font-size: ${_.rem(56)};
			color: ${_.COLORS.tradewind_blue};
			&--disabled {
				pointer-events: none;
				opacity: 0.2;
				color: ${_.COLORS.blue_600};
			}
		}

		&__card-directions {
			text-align: center;
			font-size: ${_.rem(18)};
			font-weight: 500;
			text-decoration-line: underline;
			color: ${_.COLORS.blue_600};
			/* font-style: italic; */
		}

		&__card-empty {
			${_.MIXINS.vhc};
			height: 100%;

			h3 {
				font-size: ${_.rem(16)};
				font-weight: 300;
				color: rgba(0, 0, 0, 0.54);
			}
		}

		&__card {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: ${_.COLORS.black};
			background-color: ${_.COLORS.white};

			&--top {
				align-items: center;
				justify-content: center;
				text-align: center;
				min-height: ${_.rem(220)};
				grid-column: col / span 12;

				> * {
					${_.MIXINS.vhc}
					flex-direction: column
				}
			}

			&--col-1 {
				/* background-color: ${_.COLORS.gray_300}; */
				min-height: ${_.rem(200)};
				grid-column: col / span 3;
			}

			&--col-2 {
				/* background-color: ${_.COLORS.gray_300}; */
				min-height: ${_.rem(200)};
				grid-column: col 4 / span 3;
			}

			&--col-3 {
				/* background-color: ${_.COLORS.gray_300}; */
				min-height: ${_.rem(200)};
				grid-column: col 7 / span 3;
			}

			&--col-4 {
				/* background-color: ${_.COLORS.gray_300}; */
				min-height: ${_.rem(200)};
				grid-column: col 10 / span 3;
			}

			&--bottom {
				align-items: center;
				justify-content: center;
				text-align: center;
				min-height: ${_.rem(120)};
				grid-column: col / span 12;
			}

			&-content {
				padding: 0 ${_.rem(12)};
			}
		}
	}
`

export default LocationAdminTools
