import React, { useEffect, useState } from 'react'
import { Avatar } from '@mui/material'
import { FaImage } from 'react-icons/fa'
import useMediaQuery from 'hooks/useMediaQuery'
import styled from 'styled-components'
import { Form, useForm } from 'components/controls/useForm'
import TWControls from 'components/controls/TWControls'
import FileUploader from 'components/FileUploader'
import Layout from 'components/Layout'
import Button from 'components/Button'
import * as _ from 'styles/helpers'

const UserProfile = ({ editUserProfile, recordForEdit }) => {
	const isMobile = useMediaQuery(_.MQ.small_and_below)

	const initialValues = {
		...recordForEdit,
	}

	const [image, setImage] = useState(initialValues.image)
	const [picLoading, setPicLoading] = useState(false)

	const validate = () => {
		let temp = { ...errors }
		temp.name = values.name ? '' : 'Your Name is required here.'
		// temp.firstName = values.firstName
		// 	? ''
		// 	: `Share with us a name or nickname you'd like to be addressed with .`
		// temp.email = values.email ? '' : 'A valid email address is required.'
		// temp.company = values.company ? '' : 'Your Company name is required.'
		// temp.image = values.image ? '' : 'Please upload a picture of your choosing.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) {
			editUserProfile(values, resetForm)
		}
	}

	useEffect(() => {
		if (recordForEdit != null) setValues({ ...recordForEdit })
	}, [recordForEdit, setValues])

	const uploadImage = async info => {
		setPicLoading(true)
		setImage(info.originalUrl)
		values.image = info.originalUrl
		setPicLoading(false)
	}

	return (
		<Layout>
			<Layout.Page>
				<Layout.Body>
					<StyledUserProfile isMobile={isMobile}>
						<Form onSubmit={submitHandler}>
							{/* <Card
								className="UserProfile__Card"
								header={{
									title: values.name,
									icon: <FaRegSmileWink />,
									secondary: !isMobile && (
										<Button isOutline onClick={() => handleChangePasswordButton(values)}>
											Change Password
										</Button>
									),
								}}
							> */}
							<div className="UserProfile__User">
								<Avatar src={values.image} className="UserProfile__Avatar" />
								<FileUploader onChange={info => uploadImage(info)}>
									<FaImage /> {values.image ? `Update Photo` : `Add Photo`}
								</FileUploader>
							</div>

							<h3 className="input-group-title">Personal Details</h3>
							<div className="input-group">
								<TWControls.Input
									placeholder="Full Name"
									size="small"
									name="name"
									label="Full Name"
									value={values.name}
									onChange={handleInputChange}
									fullWidth
								/>
								<TWControls.Input
									placeholder="First Name"
									size="small"
									name="firstName"
									label="First Name"
									value={values.firstName}
									onChange={handleInputChange}
									fullWidth
								/>
							</div>

							<TWControls.Input
								placeholder="Email"
								size="small"
								name="email"
								label="Email"
								value={values.email}
								onChange={handleInputChange}
								fullWidth
							/>

							<h3 className="input-group-title">Employment Details</h3>

							<div className="input-group">
								<TWControls.Input
									placeholder="Company"
									size="small"
									name="company"
									label="Company"
									value={values.company}
									onChange={handleInputChange}
									fullWidth
								/>

								<TWControls.Input
									placeholder="Title"
									size="small"
									name="title"
									label="Title"
									value={values.title}
									onChange={handleInputChange}
									fullWidth
								/>
								<TWControls.Input
									placeholder="Role"
									size="small"
									name="role"
									label="Role"
									value={values.role}
									onChange={handleInputChange}
									fullWidth
								/>
							</div>

							<h3 className="input-group-title">Social Media</h3>
							<div className="input-group">
								<TWControls.Input
									placeholder="LinkedIn"
									size="small"
									name="linkedin"
									label="LinkedIn"
									value={values.linkedin}
									onChange={handleInputChange}
									fullWidth
								/>
								<TWControls.Input
									placeholder="Instagram"
									size="small"
									name="instagram"
									label="Instagram"
									value={values.instagram}
									onChange={handleInputChange}
									fullWidth
								/>
								<TWControls.Input
									placeholder="Facebook"
									size="small"
									name="facebook"
									label="Facebook"
									value={values.facebook}
									onChange={handleInputChange}
									fullWidth
								/>
								<TWControls.Input
									placeholder="Twitter"
									size="small"
									name="twitter"
									label="Twitter"
									value={values.twitter}
									onChange={handleInputChange}
									fullWidth
								/>
							</div>

							<div className="UserProfile__Actions">
								<Button size="small" type="submit">
									Update
								</Button>
							</div>
							{/* </Card> */}
						</Form>
					</StyledUserProfile>
				</Layout.Body>
			</Layout.Page>
			{/* <Popup title="Change Password" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<UserUpdatePassword
					updatePassword={updatePassword}
					externalError={passwordError}
					setExternalError={setPasswordError}
				/>
			</Popup> */}
		</Layout>
	)
}

const StyledUserProfile = styled.div`
	max-width: ${_.rem(960)};
	margin: 0 auto;

	.UserProfile {
		&__Card {
			h2 {
				overflow: hidden;
				white-space: nowrap;
			}
		}

		&__password-buttton {
			${_.MIXINS.vhc}
			margin: ${_.rem(16)} 0 ${_.rem(24)};
		}

		&__User,
		&__Actions {
			${_.MIXINS.mod}
			${_.MIXINS.vhc}
			flex-direction: column;
			padding: ${_.rem(16)};
		}

		&__Avatar {
			width: ${_.rem(160)};
			height: ${_.rem(160)};
			margin-bottom: ${_.rem(16)};
		}
	}

	input {
		font-size: ${_.rem(18)};
	}

	.input-group {
		display: flex;
		gap: ${_.rem(16)};
		margin-bottom: ${_.rem(16)};
	}

	.input-group-title {
		margin-top: ${_.rem(16)};
		margin-bottom: ${_.rem(16)};
		font-weight: 400;
		font-size: ${_.rem(14)};
		color: ${_.COLORS.gray_500};
	}
`

export default UserProfile
