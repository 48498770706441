import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LocationAddNew from '../LocationAddNew'
import LocationsCards from './LocationsCards'
import Popup from 'components/Popup'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import Layout from 'components/Layout'
import EmptyLogo from 'images/empty_folder.png'
import useTableNoPage from 'components/controls/useTableNoPage'
import useMediaQuery from 'hooks/useMediaQuery'
import * as _ from 'styles/helpers'
import { createLocation, initializeActiveLocations } from 'slices/locationSlice'
import Placeholder from 'components/Placeholder'
import { FaPlus } from 'react-icons/fa'
import Button from 'components/Button'

const headerCells = []

const Locations = () => {
	const dispatch = useDispatch()

	const searchInputRef = useRef(null)
	const [searchInputValue, setSearchInputValue] = useState('')

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const [openPopup, setOpenPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')

	const { entities: locations, error, ids: locationIds, isLoading, isInitialized } = useSelector(
		state => state.locations,
	)
	const records = locationIds.map(id => locations[id])

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin

	const userId = user._id

	useEffect(() => {
		// if (!isInitialized) {
		dispatch(initializeActiveLocations())
		// }
	}, [dispatch, isInitialized])

	const recordCount = records.length
	const { recordsAfterPagingAndSorting } = useTableNoPage(records, headerCells, filterFn)

	const locationAdd = async (location, resetForm) => {
		const newLocation = location
		newLocation.owner = userId

		console.log(`newLocation: `, newLocation)

		if (newLocation.locationType) {
			const locationType = newLocation.locationType.toLocaleLowerCase()
			const newAssetType = locationType.split(' ')

			if (newAssetType[1]) newLocation.assetType = newAssetType[1]
		}
		await dispatch(createLocation({ newLocation }))
		await dispatch(initializeActiveLocations())
		resetForm()
		setOpenPopup(false)
	}

	const handelLocationAdd = () => {
		setPopupTitle('New Location')
		setOpenPopup(true)
	}

	const handleSearch = e => {
		const { value } = e.target
		setSearchInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.assetName.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	const isMobile = useMediaQuery(_.MQ.before_nav)

	return (
		<div>
			<React.Fragment>
				{isLoading ? (
					<Spinner containerHeight="50vh" />
				) : !records ? (
					<Message variant="danger">{error}</Message>
				) : error ? (
					<Message variant="danger">{error}</Message>
				) : records.length === 0 ? (
					<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
						<h2 className="h2">We don't have any Locations, yet.</h2>
						<div>
							<img className="details__avatar" src={EmptyLogo} alt="Avatar" />
						</div>
						<div>
							<Button onClick={handelLocationAdd} variant="success" isLarge>
								<FaPlus /> Start Here to Add a New Location
							</Button>
						</div>
					</Placeholder>
				) : (
					<>
						<Layout.PageToolbar
							buttonText="Add Location"
							buttonOnClick={() => handelLocationAdd()}
							searchInputRef={searchInputRef}
							searchInputValue={searchInputValue}
							searchInputOnChange={handleSearch}
						/>

						{/* {isMobile ? ( */}
						<LocationsCards
							locations={recordsAfterPagingAndSorting()}
							recordCount={recordCount}
							userId={userId}
						/>
						{/* ) : (
						<>
							<LocationsTable
								locations={recordsAfterPagingAndSorting()}
								openInMap={openInMap}
								TblContainer={TblContainer}
								TblHeader={TblHeader}
								TblPagination={TblPagination}
								recordCount={recordCount}
								userId={userId}
							/>
						</>
					)} */}
					</>
				)}
			</React.Fragment>

			{openPopup && (
				<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
					<LocationAddNew locationAdd={locationAdd} user={userId} />
				</Popup>
			)}
		</div>
	)
}

export default Locations
