import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const SLICE_NAME = 'invitedUser'

const initialState = {
	loading: true,
	invitedUser: {},
	error: null,
}

export const inviteNewUser = createAsyncThunk(
	`${SLICE_NAME}/inviteNewUser`,
	async (payload: { newUser: string; inviterId: string; assetType: string; assetId: string }) => {
		const { newUser, inviterId, assetType, assetId } = payload

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const data = await axios
			.post('/api/invitedusers/', { newUser, inviterId, assetType, assetId }, config)
			.then(res => {
				return res.data
			})

		if (data) {
			return data
		} else {
			return null
		}
	},
)

const invitedUserSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {},
	extraReducers: {
		[inviteNewUser.pending as any]: () => ({ ...initialState }),

		[inviteNewUser.fulfilled as any]: (state, action) => ({
			...state,
			invitedUser: action.payload,
			loading: false,
		}),

		[inviteNewUser.rejected as any]: (state, action) => ({
			...state,
			loading: false,
			error: action.error,
		}),
	} as any,
})

export default invitedUserSlice.reducer
