import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
const POST = 'post'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const createPost = createAsyncThunk(
	`${POST}/create`,
	async (post, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/posts`, post, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create post')
		}
	},
)

export const createPostImage = createAsyncThunk(
	`${POST}/createImage`,
	async (postImage, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/posts/image`, postImage, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create post  image')
		}
	},
)

export const initializePosts = createAsyncThunk(
	`${POST}/initializePosts`,
	async (_, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/posts/myposts`)
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Failed to get posts')
		}
	},
)

export const getPosts = createAsyncThunk(
	`${POST}/getPosts`,
	async (locationId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/posts/${locationId}`, prepConfig(getState))
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Failed to get posts')
		}
	},
)

// export const likeMyPost = createAsyncThunk(
// 	`${POST}/like`,
// 	async (payload: any, { getState, rejectWithValue }) => {
// 		const { postId, likes } = payload
// 		try {
// 			const { data } = await axios.put(`/api/posts/${postId}/like`, likes, prepConfig(getState))
// 			return data
// 		} catch (error) {
// 			return rejectWithValue('Failed to like my post')
// 		}
// 	},
// )

export const updatePost = createAsyncThunk(
	`${POST}/update`,
	async (post: any, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.put(`/api/posts/${post._id}`, post, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to update post')
		}
	},
)

const postSlice = createSlice({
	name: POST,
	initialState,
	reducers: {},
	extraReducers: {
		[createPost.fulfilled as any]: (state, action) => {
			const newPost = action.payload
			state.entities[newPost._id] = newPost
		},
		[createPostImage.fulfilled as any]: (state, action) => {
			const newPost = action.payload
			state.entities[newPost._id] = newPost
		},
		[initializePosts.fulfilled as any]: (state, action) => {
			const posts = action.payload
			state.ids = posts?.map((post, index) => {
				state.entities[post._id] = post
				return post._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializePosts.pending as any]: state => {
			state.isLoading = true
		},
		// [likeMyPost.fulfilled as any]: (state, action) => {
		// 	const likedPost = action.payload
		// 	state.entities[likedPost._id] = likedPost
		// },
		[updatePost.fulfilled as any]: (state, action) => {
			const updatedPost = action.payload
			state.entities[updatedPost._id] = updatedPost
		},
	} as any,
})

export default postSlice

// export const { } = locationsSlice.actions;
