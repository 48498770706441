import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Avatar, Fab, Grid, IconButton } from '@mui/material'
import {
	Create as CreateIcon,
	MailOutline as MailOutlineIcon,
	Send as SendIcon,
} from '@mui/icons-material'
import { IoEnterOutline } from 'react-icons/io5'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'
import { updateTask } from 'slices/tasksSlice'
import Popup from 'components/Popup/Popup'
import SendEmailPopup from './SendEmailPopup'
import { emailTaskPopup } from 'functions/userEmails'

const TaskAddEditPopup = ({ partners, addEditTask, recordForEdit, parentId }) => {
	const dispatch = useDispatch()

	const [emailRecord, setEmailRecord] = useState(null)
	const [openEmailPopup, setOpenEmailPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')

	const SERVER_BASE_URL =
		process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.mydealteams.com'

	const statusTypes = [
		{ id: 1 },
		{ selectValue: 'Open' },
		{ id: 2 },
		{ selectValue: 'In Progress' },
		{ id: 3 },
		{ selectValue: 'On Hold' },
		{ id: 4 },
		{ selectValue: 'Completed' },
	]

	const [input, setInput] = useState('')

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const userId = userInfo._id

	const task = recordForEdit
	const taskCompleted = task.taskStatus === 'Completed' ? true : false

	const locationId = task.packageid
	const projectId = task.projectid

	const { isLoading, isInitialized, location } = useSelector(state => {
		return {
			isLoading: state.locations.isLoading,
			isInitialized: state.locations.isInitialized,
			location: state.locations.entities[locationId],
		}
	})

	const { projectLoading, projectInitialized, project } = useSelector(state => {
		return {
			projectLoading: state.projects.isLoading,
			projectInitialized: state.projects.isInitialized,
			project: state.projects.entities[projectId],
		}
	})

	const taskNoteCommentHandler = async () => {
		const updatedTask = task
		const newComments = [...task.comments, { commenter: userId, comment: input }]

		updatedTask.comments = newComments

		debugger

		const newTask = {
			_id: updatedTask.id,
			taskName: updatedTask.taskName,
			parentId: updatedTask.parentId,
			projectid: updatedTask.projectid,
			packageid: updatedTask.packageid,
			portfolioid: updatedTask.portfolioid,
			packageType: updatedTask.packageType,
			createdBy: updatedTask.userId,
			assignedTo: updatedTask.assignedTo,
			description: updatedTask.description,
			taskStatus: updatedTask.taskStatus,
			completedBy: updatedTask.completedBy,
			dateStarted: updatedTask.dateStarted,
			dateCompleted: updatedTask.dateCompleted,
			completed: updatedTask.isComplete,
			comments: updatedTask.comments,
		}

		console.log(`newTask: `, newTask)

		await dispatch(updateTask(newTask))
	}

	const defaultValues = {
		_id: 0,
		parentId,
		assignedTo: '',
		taskName: '',
		description: '',
		completedBy: '',
		completed: false,
	}

	const initialValues = {
		...defaultValues,
		...task,
	}

	const validate = () => {
		let temp = { ...errors }
		temp.assignedTo = values.assignedTo ? '' : 'This field is required.'
		temp.taskName = values.taskName ? '' : 'This field is required.'
		temp.completedBy = values.completedBy ? '' : 'Email is not valid.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const sendEmailHandler = emailKit => {
		const pushLink = `${SERVER_BASE_URL}/locations/${task.locationId}/projects/${task.projectId}/tasks`

		emailTaskPopup(emailKit, pushLink)
	}

	const emailPartnersHandler = async () => {
		const emailKit = {
			fromName: userInfo.name,
			fromFirstName: userInfo.firstName,
			fromEmail: userInfo.email,
			toName: task.assignedToName,
			toFirstName: task.firstName,
			toEmail: task.assignedToEmail,
			taskName: task.taskName,
			taskLocation: location.assetName,
			taskProject: project.projectName,
			taskDue: moment(task.completedBy).format('MM/DD/YYYY'),
			taskStatus: task.taskStatus,
			taskNotes: task.description,
			emailSubject: `${location.assetName} | ${project.projectName} | ${task.taskName}`,
			emailBody: '',
		}

		console.log(`values: `, emailKit)
		setEmailRecord(emailKit)
		setPopupTitle('Compose Email . . .')
		setOpenEmailPopup(true)
	}

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) {
			addEditTask(values, resetForm)
		}
	}

	useEffect(() => {
		if (task != null) setValues({ ...task })
	}, [task, setValues])

	if (!location || isLoading) {
		return null
	}

	if (!project || projectLoading) {
		return null
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<TWControls.Input
						name="taskName"
						label="Task Name"
						value={values.taskName}
						onChange={handleInputChange}
						error={errors.taskName}
					/>
					<Grid container spacing={2}>
						<Grid item xs={10}>
							<TWControls.Select
								name="assignedTo"
								label="Assigned To"
								value={values.assignedTo}
								onChange={handleInputChange}
								options={partners}
								error={errors.assignedTo}
							/>
						</Grid>
						<Grid item xs={2}>
							<IconButton
								style={{ marginLeft: '6px' }}
								// color="primary"
								onClick={() => emailPartnersHandler(values)}
							>
								<MailOutlineIcon />
							</IconButton>
						</Grid>
					</Grid>

					<TWControls.DatePicker
						name="completedBy"
						label="Due Date"
						value={values.completedBy ? values.completedBy : null}
						onChange={handleInputChange}
						error={errors.completedBy}
					/>
				</Grid>
				<Grid item xs={6}>
					<TWControls.SelectType
						className="feed_select"
						disabled={taskCompleted}
						// size="small"
						name="taskStatus"
						label="Task Status"
						value={values.taskStatus}
						onChange={handleInputChange}
						options={statusTypes}
					/>
					<TWControls.Input
						disabled={true}
						name="dateStarted"
						label="Date Started"
						value={values.dateStarted && moment(values.dateStarted).format('MM/DD/YYYY')}
						// onChange={handleInputChange}
						error={errors.dateStarted}
					/>
					<TWControls.Input
						disabled={true}
						name="dateCompleted"
						label="Date Completed"
						value={values.dateCompleted && moment(values.dateCompleted).format('MM/DD/YYYY')}
						// onChange={handleInputChange}
						error={errors.dateCompleted}
					/>
				</Grid>
				<Grid item xs={12}>
					<TWControls.InputMulti
						name="description"
						label="Notes"
						value={values.description}
						onChange={handleInputChange}
						error={errors.description}
					/>

					<div className="newLocation__imageHolder">
						{!taskCompleted && <TWControls.Button size="small" type="submit" text="Update Task" />}
					</div>
				</Grid>
			</Grid>
			{openEmailPopup && (
				<Popup title={popupTitle} openPopup={openEmailPopup} setOpenPopup={setOpenEmailPopup}>
					<SendEmailPopup sendEmailHandler={sendEmailHandler} emailKit={emailRecord} />
				</Popup>
			)}
		</Form>
	)
}

export default TaskAddEditPopup
