import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTableNoPage from 'components/controls/useTableNoPage'
import swal from 'sweetalert'
import { inviteNewUser } from 'slices/invitedUserSlice'
import { initializeUsers } from 'slices/usersSlice'
import { initializeMyPartners } from 'slices/myPartnersSlice'
import UserAddNew from 'components/UserAddNew'
import Popup from 'components/Popup'
import { useParams } from 'react-router-dom'
import { CardGrid, PartnerCard } from 'components/Card'
import Button from 'components/controls/Button'
import { addPartnerToProject, getUpdatedProject } from 'slices/projectSlice'
import { emailInviteNewUser, emailInviteNewUsercc } from 'functions/loginRegisterEmails'
import Layout from 'components/Layout/Layout'

const headerCells = [
	{ id: 'image', label: 'Image' },
	{ id: 'name', label: 'Partner' },
	{ id: 'title', label: 'Title' },
	{ id: 'company', label: 'Company' },
	{ id: 'email', label: 'Email' },
	{ id: 'update', label: 'Add Partner', disableSorting: true },
]

const LocationAddPartners = ({ history, match }) => {
	const dispatch = useDispatch()

	const { id } = useParams()
	const { projectId } = useParams()

	const SERVER_BASE_URL =
		process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.mydealteams.com'

	const [openPopup, setOpenPopup] = useState(false)

	const searchInputRef = useRef(null)
	const [searchInputValue, setSearchInputValue] = useState('')

	const locationId = match.params.id

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin
	const userId = user._id

	const myPartnersSelector = useSelector(state => state.myPartners.myPartnersList)
	const { data: myPartnersList, loading, error } = myPartnersSelector

	const { myPartners } = useSelector(state => {
		const myPartnerIds = state.myPartners.ids
		const myPartners = myPartnerIds.map(id => state.myPartners.entities[id])
		const { error, isInitialized } = state.myPartners
		return { myPartners, error, isInitialized }
	})

	const { isLoading, success, location } = useSelector(state => {
		return {
			isLoading: state.locations.isLoading,
			success: state.locations.isInitialized,
			location: state.locations.entities[id],
		}
	})

	const { projectLoading, projectSuccess, project } = useSelector(state => {
		return {
			projectLoading: state.projects.isLoading,
			projectSuccess: state.projects.isInitialized,
			project: state.projects.entities[projectId],
		}
	})

	const fromUser = {
		id: user._id,
		name: user.name,
		email: user.email,
	}

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	useEffect(() => {
		// if (!location || location._id !== locationId) {
		// 	dispatch(getLocationByID(locationId))
		// }
		// dispatch(getUpdatedProject(projectId))
		dispatch(initializeMyPartners())
	}, [location, locationId, dispatch, user])

	const records = myPartners
	const { TblContainer, TblHeader, recordsAfterPagingAndSorting } = useTableNoPage(
		records || [],
		headerCells,
		filterFn,
	)

	const userAdd = async (newUser, resetForm) => {
		const emailUser = await dispatch(
			inviteNewUser({ newUser, inviterId: userId, assetType: 'locations', assetId: locationId }),
		)

		if (emailUser._id) {
			const newId = emailUser._id

			const toUser = {
				name: newUser.name,
				firstName: newUser.firstName,
				email: newUser.email,
			}

			swal({
				title: `Partner Invited!`,
				text: `Good job. You've invited ${toUser.name} to partner with you in your work on this location. An invitation has been emailed to ${toUser.firstName} to join TradeWind as well as a cc to you.`,
				icon: 'success',
				button: 'Ok',
			})

			const pushLink = `${SERVER_BASE_URL}/registerconfirm/?id=${newId}&name=${newUser.name}&firstname=${newUser.firstName}&email=${newUser.email}`

			emailInviteNewUser(fromUser, toUser, location, pushLink)
			emailInviteNewUsercc(fromUser, toUser, location, pushLink)
		}

		resetForm()
		setOpenPopup(false)
		dispatch(getUpdatedProject(projectId))
		dispatch(initializeUsers())
		dispatch(initializeMyPartners())
	}

	const handleSearch = e => {
		const { value } = e.target
		setSearchInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.name.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	function isInProject(myPartner) {
		const exists = !!project?.partners?.find(
			projectPartner => projectPartner.partnerUserId._id === myPartner.partner._id,
		)
		// ) || !myPartner.isAccepted
		return exists
	}

	function handleAddPartner(partner) {
		dispatch(
			addPartnerToProject({
				projectId: projectId,
				partner,
			}),
		)

		// TODO - this should be triggered on a successful redux trigger so false positives aren't possible
		swal({
			title: `Partner Added!`,
			text: `Good job. ${partner.name} will be added as a partner to this project, pending ${partner.firstName}'s acceptance. An acknowledgement of this request has been sent to ${partner.firstName} as well as a cc to you.`,
			icon: 'success',
			button: 'Ok',
		})
		dispatch(getUpdatedProject(projectId))
		dispatch(initializeMyPartners())
	}

	return (
		<div>
			<React.Fragment>
				<Layout.PageToolbar
					buttonText="Add New Partner"
					buttonOnClick={() => setOpenPopup(true)}
					searchInputRef={searchInputRef}
					searchInputValue={searchInputValue}
					searchInputOnChange={handleSearch}
				/>
			</React.Fragment>
			<CardGrid>
				{recordsAfterPagingAndSorting().map(partner => {
					if (!isInProject(partner)) {
						return (
							<PartnerCard
								key={partner.partner._id}
								name={partner.partner.name}
								title={partner.partner.title}
								companyName={partner.partner.company}
								avatarUrl={partner.partner.image}
								actions={
									<Button
										isOutline
										onClick={() => handleAddPartner(partner)}
										text={partner.isAccepted ? 'Connect' : 'Pending'}
									/>
								}
							/>
						)
					}
				})}
			</CardGrid>
			<Popup title="New Partner" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<UserAddNew fromUser={fromUser} userAdd={userAdd} />
			</Popup>
		</div>
	)
}

export default LocationAddPartners
