import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { FaPlus, FaFileAlt } from 'react-icons/fa'
import Popup from 'components/Popup'
import Layout from 'components/Layout/Layout'
import SiteDetailTemplatesSelect from 'components/Sidebars/SiteDetailTemplatesSelect'
import ListAction from 'features/Actions/components/ListAction'
import Spinner from 'components/Spinner'
import Details from 'features/Details'
import { useDetails } from 'features/Details/DetailsContext'
import { patchLocation } from 'slices/locationSlice'

import {
	createSiteDetail,
	getSiteDetails,
	updateSiteDetail,
	loadTemplateIntoSiteDetails,
} from 'slices/siteDetailsSlice'
import { initializeTemplates } from 'slices/templateSlice'
import DetailAddPopup from 'components/popups/DetailAddPopup'
import { patchProject } from 'slices/projectSlice'

const LocationDetailsScreen = ({ location, project, user }) => {
	const dispatch = useDispatch()

	const locationId = location._id
	const projectId = project._id
	const userId = user._id

	const {
		state: { openPopup, openDeletePopup, popupTitle, recordForEdit, deleteDetail },
		actions: { setOpenPopup, setOpenDeletePopup, setPopupTitle, setRecordForEdit, setDeleteDetail },
	} = useDetails()

	const [openNewDetailPopup, setOpenNewDetailPopup] = useState(false)
	const [openTemplatePopup, setOpenTemplatePopup] = useState(false)

	const siteDetailsSelector = useSelector(state => state.siteDetails)
	const { siteDetails, loading: isSiteDetailsLoading, isInitialized } = siteDetailsSelector

	const {
		entities: templateEntities,
		ids: templateIds,
		isInitialized: templatesInitialized,
	} = useSelector(state => state.templates)
	const newTemplates = templateIds.map(id => templateEntities[id])
	const siteDetailTemplates = newTemplates.filter(item => item.templateType !== 'task')

	useEffect(() => {
		if (!isInitialized) {
			dispatch(getSiteDetails({ projectId }))
		}
		dispatch(initializeTemplates())
	}, [dispatch, isInitialized, userId])

	const addDetail = async (siteDetail, resetForm) => {
		const detailLabel = siteDetail.detailLabel
		const description = siteDetail.description
		const detailType = siteDetail.detailType
		const detail = siteDetail.detail

		await dispatch(
			createSiteDetail({
				packageid: locationId,
				projectid: projectId,
				packageType: 'location',
				detailLabel,
				description: description,
				detail: detail,
				detailType: detailType,
				detailTextValue: detailType === 'Text' ? detail : null,
				detailNumericValue: detailType === 'Number' ? detail : null,
				detailCurrencyValue: detailType === 'Currency' ? detail : null,
				detailDateValue: detailType === 'Date' ? detail : null,
			}),
		)

		dispatch(
			patchLocation({ locationId: locationId, updates: { updateNote: 'New Detail(s) Added' } }),
		)
		dispatch(patchProject({ projectId: projectId, updates: { updateNote: 'New Detail(s) Added' } }))

		dispatch(getSiteDetails({ projectId }))

		resetForm()
		setOpenPopup(false)
		setOpenNewDetailPopup(false)
		setOpenTemplatePopup(false)
		setRecordForEdit(null)
	}

	const handleEditDeleteDetail = async (siteDetail, resetForm) => {
		const detailLabel = siteDetail.detailLabel
		const description = siteDetail.description
		const detailType = siteDetail.detailType
		const detail = siteDetail.detail

		console.log(`LDS - Delete`)

		await dispatch(
			updateSiteDetail({
				_id: siteDetail._id,
				packageid: locationId,
				projectid: projectId,
				packageType: 'location',
				userid: userId,
				detailLabel,
				description: description,
				detail: detail,
				detailType: detailType,
				detailTextValue: detailType === 'Text' ? detail : null,
				detailNumericValue: detailType === 'Number' ? detail : null,
				detailCurrencyValue: detailType === 'Currency' ? detail : null,
				detailDateValue: detailType === 'Date' ? detail : null,
			}),
		)

		dispatch(
			patchLocation({
				locationId: locationId,
				updates: { updateNote: 'Details(s) Deleted' },
			}),
		)
		dispatch(patchProject({ projectId: projectId, updates: { updateNote: 'Detail(s) Deleted' } }))

		dispatch(getSiteDetails({ projectId }))

		resetForm()
		setOpenPopup(false)
		setOpenNewDetailPopup(false)
		setOpenTemplatePopup(false)
		setRecordForEdit(null)
	}

	const handleTemplateLoad = async (templateId, resetForm) => {
		const template = {
			userId: userId,
			packageid: locationId,
			projectid: projectId,
			packageType: 'location',
			templateId: templateId,
		}

		await dispatch(loadTemplateIntoSiteDetails(template))

		resetForm()
		setOpenTemplatePopup(false)

		dispatch(getSiteDetails({ projectId }))
	}

	const handleAddDetail = () => {
		const newDetail = {
			_id: 0,
			detailLabel: '',
			description: '',
			detailType: '',
			detail: '',
		}
		setPopupTitle('New Detail')
		setRecordForEdit(newDetail)
		setOpenNewDetailPopup(true)
	}

	const handleAddTemplate = () => {
		setPopupTitle('New Template')
		setRecordForEdit({})
		setOpenTemplatePopup(true)
	}

	// TODO: Create skeleton loader
	// if (isLocationLoading || isSiteDetailsLoading) return <Spinner containerHeight="50vh" />

	return (
		<Grid container spacing={3}>
			<Grid item xs={9}>
				<Details details={siteDetails} handleSubmit={handleEditDeleteDetail} />
			</Grid>
			<Grid item xs={3}>
				<Layout.SidebarItem title="Actions" hasNoMaxHeight>
					<ul>
						<li className="actionList__item">
							<ListAction
								handleAction={handleAddDetail}
								actionLable={'Add New Detail'}
								actionIcon={<FaPlus />}
							/>
						</li>

						<li className="actionList__item">
							<ListAction
								handleAction={handleAddTemplate}
								actionLable={'Detail Templates'}
								actionIcon={<FaFileAlt />}
							/>
						</li>
					</ul>
				</Layout.SidebarItem>
			</Grid>
			{openNewDetailPopup ? (
				<Popup
					title={popupTitle}
					openPopup={openNewDetailPopup}
					setOpenPopup={setOpenNewDetailPopup}
					isFullWidth={false}
				>
					<DetailAddPopup recordForEdit={recordForEdit} addDetail={addDetail} />
				</Popup>
			) : null}

			{openTemplatePopup ? (
				<Popup
					title="Site Detail Templates"
					openPopup={openTemplatePopup}
					setOpenPopup={setOpenTemplatePopup}
				>
					<SiteDetailTemplatesSelect
						detailTemplates={siteDetailTemplates}
						handleTemplateLoad={handleTemplateLoad}
					/>
				</Popup>
			) : null}
		</Grid>
	)
}

export default LocationDetailsScreen
