import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaTasks } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material'
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material/'
import Layout from 'components/Layout'
import Message from '../Message'
import { initializeRecentTasks } from 'slices/tasksSlice'

function SidebarPendingTasksHome() {
	const dispatch = useDispatch()

	const { userInfo: user } = useSelector(state => state.userLogin)
	const userid = user._id

	const {
		entities: taskEntities,
		ids: taskIds,
		isLoading: tasksLoading,
		isInitialized: tasksInitialized,
		error,
	} = useSelector(state => state.tasks)
	const tasks = taskIds.map(id => taskEntities[id])

	useEffect(() => {
		dispatch(initializeRecentTasks({ userid }))
	}, [dispatch])

	const isEmpty = tasks?.length === 0

	return (
		<Layout.SidebarItem
			title="Pending Tasks"
			icon={<FaTasks />}
			isEmpty={isEmpty}
			hasNoMaxHeight
			noPadding
		>
			{tasksInitialized ? (
				<List dense disablePadding>
					{tasks.map(task => {
						switch (task.packageType) {
							case 'center':
								return (
									<ListItem
										key={task._id}
										button
										component={Link}
										to={`/centers/${task.packageid}`}
									>
										<ListItemText primary={task.taskName} secondary={task.description} />
										<ListItemSecondaryAction className="secondary-action">
											<IconButton edge="end" aria-label="delete">
												<ChevronRightIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								)
							case 'location':
								return (
									<ListItem
										key={task._id}
										button
										component={Link}
										to={`/locations/${task.packageid}`}
									>
										<ListItemText primary={task.taskName} secondary={task.description} />
										<ListItemSecondaryAction className="secondary-action">
											<IconButton edge="end" aria-label="delete">
												<ChevronRightIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								)
							case 'portfolio':
								return (
									<ListItem
										key={task._id}
										button
										component={Link}
										to={`/portfolios/${task.packageid}`}
									>
										<ListItemText primary={task.taskName} secondary={task.description} />
										<ListItemSecondaryAction className="secondary-action">
											<IconButton edge="end" aria-label="delete">
												<ChevronRightIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								)
							default:
								return null
						}
					})}
				</List>
			) : null}
		</Layout.SidebarItem>
	)
}

export default SidebarPendingTasksHome
