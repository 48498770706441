import React from 'react'
import SidebarCommunityAccordian from './SidebarCommunityAccordian'
import SidebarPendingTasksHome from './SidebarPendingTasksHome'
import SidebarPendingTasksCenter from './SidebarPendingTasksCenter'
import SidebarPendingTasksLocation from './SidebarPendingTasksLocation'
import SidebarPendingTasksPortfolio from './SidebarPendingTasksPortfolio'
import Layout from 'components/Layout'

function SidebarCommunity({ assetType }) {
	return (
		<>
			{/* <Layout.SidebarUserCard /> */}
			<SidebarCommunityAccordian />
			{(() => {
				switch (assetType) {
					case 'location':
						return <SidebarPendingTasksLocation />
					case 'center':
						return <SidebarPendingTasksCenter />
					case 'portfolio':
						return <SidebarPendingTasksPortfolio />
					default:
						return <SidebarPendingTasksHome />
				}
			})()}
		</>
	)
}

export default SidebarCommunity
