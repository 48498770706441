import React from 'react'
import Layout from 'components/Layout'
import SidebarCommunity from 'components/Sidebars/SidebarCommunity'
import RightbarMyPartners from 'components/Sidebars/RightbarMyPartners'
import Feed from 'components/Feed'

const Community_Page = () => {
	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<SidebarCommunity assetType={'home'} />
				</Layout.Sidebar>
				<Layout.Body>
					<Feed />
				</Layout.Body>
				<Layout.SidebarNews>
					<RightbarMyPartners />
				</Layout.SidebarNews>
			</Layout.Page>
		</Layout>
	)
}

export default Community_Page
