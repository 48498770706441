import React from 'react'
import { useSelector } from 'react-redux'

import Layout from 'components/Layout'
import HomeWorking from './HomeWorking'
import HomeFirstTime from './HomeFirstTime'
import HomeGuest from './HomeGuest'

const Home = () => {
	const userLogin = useSelector((state: any) => state.userLogin)
	const { userInfo: user } = userLogin

	return (
		<Layout>
			{user.isGuestOnly ? <HomeGuest /> : user.firstTime ? <HomeFirstTime /> : <HomeWorking />}
		</Layout>
	)
}

export default Home
