import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from 'components/Layout'
import RenderSubRoutes from 'components/RenderSubRoutes'
import PortfolioSidebarTop from './components/PortfolioSidebarTop'
import PortfolioSidebarPartners from './components/PortfolioSidebarPartners'
import getPortfolioRoutes from './getPortfolioRoutes'
import routes from 'constants/routes'
import useMediaQuery from 'hooks/useMediaQuery'
import * as _ from 'styles/helpers'
import { initializePortfolios, getUpdatedPortfolio } from 'slices/portfolioSlice'
import { initializeUsers } from 'slices/usersSlice'
import getPortfolioPartnerRoutes from './getPortfolioPartnerRoutes'
//

const Portfolios_Show = () => {
	const dispatch = useDispatch()
	const params = useParams()
	const { id } = params

	const currentRoute = useLocation()

	const pathArray = currentRoute.pathname.split('/')
	const isPartnerPage = pathArray[pathArray.length - 1] === 'partners'

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const { isLoading, portfolio, isInitialized } = useSelector(state => {
		return {
			isLoading: state.portfolios.isLoading,
			isInitialized: state.portfolios.isInitialized,
			portfolio: state.portfolios.entities[id], // || {},
		}
	})

	const { success: userListInitialized } = useSelector(state => {
		return {
			success: state.userList.isInitialized,
		}
	})

	useEffect(() => {
		if (!isInitialized) {
			dispatch(initializePortfolios())
		} else {
			dispatch(getUpdatedPortfolio(id))
		}
		if (!userListInitialized) {
			dispatch(initializeUsers())
		}
	}, [dispatch, isInitialized, userListInitialized])

	const partners = portfolio?.partners
	const assetOwner = portfolio?.owner

	const isMobile = useMediaQuery(_.MQ.before_nav)

	if (!portfolio || isLoading) {
		return null
	}

	var subRoutes = []
	if (assetOwner._id === userInfo._id) {
		subRoutes = getPortfolioRoutes(id)
	} else {
		subRoutes = getPortfolioPartnerRoutes(id)
	}

	return (
		<Layout>
			<Layout.SecondaryNav
				links={subRoutes}
				backLink={{ title: 'Portfolios', link: routes.PORTFOLIOS }}
			/>
			<Layout.Page>
				<Layout.Sidebar isOffset>
					{portfolio && (
						<>
							<PortfolioSidebarTop portfolio={portfolio} />
							<PortfolioSidebarPartners
								assetOwner={assetOwner}
								assetPartners={partners}
								assetName={portfolio?.assetName}
								userId={userInfo._id}
							/>
						</>
					)}
				</Layout.Sidebar>
				<Layout.Body>
					<RenderSubRoutes routes={subRoutes} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Portfolios_Show
