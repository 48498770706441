import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProjectsCards from './ProjectsCards'
import SidebarProjectsRecent from 'components/Sidebars/SidebarProjectsRecent'
import SidebarPendingTasksProject from 'components/Sidebars/SidebarPendingTasksProject'
import Popup from 'components/Popup'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import Layout from 'components/Layout'
import EmptyLogo from 'images/empty_folder.png'
import useTableNoPage from 'components/controls/useTableNoPage'
import useMediaQuery from 'hooks/useMediaQuery'
import * as _ from 'styles/helpers'
import { initializeMyProjects } from 'slices/projectSlice'
import Placeholder from 'components/Placeholder'
import { FaPlus } from 'react-icons/fa'
import Button from 'components/Button'

const headerCells = [
	{ id: 'Image', label: '', disableSorting: true },
	{ id: 'projectName', label: 'Project' },
	{ id: 'projectDescription', label: 'Description' },
	{ id: 'projectType', label: 'Project Type' },
	{ id: 'update', label: 'Edit' },
]

const Projects = ({ openPopup, setOpenPopup }) => {
	const dispatch = useDispatch()

	const searchInputRef = useRef(null)
	const [searchInputValue, setSearchInputValue] = useState('')

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const { entities: projects, error, ids: projectIds, isLoading, isInitialized } = useSelector(
		state => state.projects,
	)

	const records = projectIds.map(id => projects[id])

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin

	const userId = user._id

	useEffect(() => {
		dispatch(initializeMyProjects())
	}, [dispatch, isInitialized])

	const recordCount = records.length
	const { recordsAfterPagingAndSorting } = useTableNoPage(records, headerCells, filterFn)

	const handleSearch = e => {
		const { value } = e.target
		setSearchInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.projectName.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	return (
		<React.Fragment>
			{isLoading ? (
				<Spinner containerHeight="50vh" />
			) : !records ? (
				<Message variant="danger">{error}</Message>
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : records.length === 0 ? (
				<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
					<h2 className="h2">We don't have any Projects, yet.</h2>
					<div>
						<img className="details__avatar" src={EmptyLogo} alt="Avatar" />
					</div>
					<div>Projects are created and assigned to specific locations.</div>
					<div>To create a new project select a Location in the Locations section.</div>
				</Placeholder>
			) : (
				<>
					<Layout.PageToolbar_NoPlus
						buttonText="Add Project"
						buttonOnClick={() => setOpenPopup(true)}
						searchInputRef={searchInputRef}
						searchInputValue={searchInputValue}
						searchInputOnChange={handleSearch}
					/>
					<ProjectsCards
						projects={recordsAfterPagingAndSorting()}
						recordCount={recordCount}
						userId={userId}
					/>
				</>
			)}
		</React.Fragment>
	)
}

export default Projects
