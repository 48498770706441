import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Spinner from './Spinner'
import Message from './Message'
import Post from './Post'
import { Create as CreateIcon } from '@mui/icons-material'
import FileUploader from './FileUploader'
import { FaImage, FaVideo } from 'react-icons/fa'
import { createPost, createPostImage, initializePosts } from 'slices/postSlice'

const Feed = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const {
		entities: postEntities,
		ids: postIds,
		isLoading: postsLoading,
		error: errorFetch,
	} = useSelector(state => state.posts)
	const posts = postIds.map(id => postEntities[id])

	const POST_PROMPT = 'Start a post'
	const [input, setInput] = useState('')
	const [image, setImage] = useState('')
	const [video, setVideo] = useState('')

	useEffect(() => {
		dispatch(initializePosts())
	}, [history, dispatch])

	const sendPost = () => {
		dispatch(
			createPost({
				user: userInfo._id,
				message: input,
				image: image,
				video: video,
			}),
		)
	}

	const sendPostImage = async info => {
		const elements = info.name.split('.')
		const imageExt = elements.slice(elements.length - 1).toString()

		setImage(info.secure_url)

		dispatch(
			createPostImage({
				userid: userInfo._id,
				uuid: info.uuid,
				name: info.name,
				size: info.size,
				url: info.originalUrl,
				source: info.source,
				type: imageExt,
			}),
		)
	}
	return (
		<div className="feed">
			<div className="feed_inputContainer">
				<div>{image ? <img className="feed__image" src={image} alt="" /> : null}</div>
				<div>{video ? <video className="feed__image" src={video} alt="" /> : null}</div>

				<div className="feed_input">
					<form>
						<CreateIcon />
						<input
							value={input}
							onChange={e => setInput(e.target.value)}
							type="text"
							placeholder={POST_PROMPT}
						/>
						<button onClick={sendPost} type="submit"></button>
					</form>
				</div>

				<div className="feed__inputOptions">
					<div style={{ display: 'flex' }}>
						<FileUploader
							onChange={info => {
								sendPostImage(info)
								setImage(info.cdnUrl)
							}}
						>
							<FaImage /> Image
						</FileUploader>
						<FileUploader
							onChange={info => {
								sendPostImage(info)
								setVideo(info.cdnUrl)
							}}
						>
							<FaVideo /> Video
						</FileUploader>
					</div>
				</div>
			</div>

			{postsLoading ? (
				<Spinner />
			) : errorFetch ? (
				<Message variant="danger">{errorFetch}</Message>
			) : (
				<>
					{posts.map(post => (
						<Post key={post._id} post={post} />
					))}
				</>
			)}
		</div>
	)
}

export default Feed
