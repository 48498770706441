import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { CompatRoute, Navigate } from 'react-router-dom-v5-compat'

const RenderSubRoutes = ({ routes }: any) => {
	// console.log(`RenderSubRoute: `)

	return (
		<Switch>
			{routes.map((link: any, index: number) => {
				if (link.redirectFrom && link.redirectTo) {
					return (
						<CompatRoute key={index} path={link.redirectFrom} exact>
							<Navigate to={link.redirectTo} />
						</CompatRoute>
					)
				}
				return <CompatRoute key={index} path={link.linkPattern} exact component={link.component} />
			})}
		</Switch>
	)
}

export default RenderSubRoutes
