import React from 'react'
import { FaTelegramPlane } from 'react-icons/fa'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import Layout from 'components/Layout'

function LocationSidebarPartners({ assetOwner, assetName, assetPartners, userId }) {
	const owner = [
		{
			id: assetOwner._id,
			image: assetOwner.image,
			name: assetOwner.name,
			email: assetOwner.email,
			title: assetOwner.title,
			company: assetOwner.company,
			owner: true,
		},
	]

	const partners = assetPartners.map(item => {
		return {
			id: item.partner._id,
			image: item.partner.image,
			name: item.partner.name,
			email: item.partner.email,
			title: item.partner.title,
			company: item.partner.company,
			owner: false,
		}
	})

	const newPartners = [...owner, ...partners]

	const allPartners = newPartners.filter(item => item.id !== userId)

	return (
		<Layout.Sidebar title="Partners">
			<List dense>
				{assetPartners ? (
					allPartners.map(partner => (
						<ListItem className="listGroup__task" key={partner._id}>
							<ListItemAvatar>
								<Avatar src={partner.image} alt={partner.image} />
							</ListItemAvatar>
							<ListItemText
								primary={
									<a href={`mailto: ${partner.name} (${partner.email})?subject=${assetName}`}>
										<div className="feed__collaboratorName">
											{`${partner.name} `}
											<FaTelegramPlane />
										</div>
										<div className="feed__collaboratorName">
											{partner.owner && `(portfolio originator)`}
										</div>
									</a>
								}
								secondary={
									<React.Fragment>
										<Typography component="span" variant="body2" color="textPrimary">
											{partner.title}
											{' - '}
										</Typography>
										{partner.company}
									</React.Fragment>
								}
							/>
						</ListItem>
					))
				) : (
					<span>No Partners</span>
				)}
			</List>
		</Layout.Sidebar>
	)
}

export default LocationSidebarPartners
