import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaPlus, FaFileAlt } from 'react-icons/fa'
import swal from 'sweetalert'
import Button from 'components/Button'
import EmptyLogo from 'images/empty_folder.png'
import Spinner from 'components/Spinner'
import Message from 'components/Message'
import Task from 'components/Task'
import Popup from 'components/Popup'
import TaskAddPopup from 'components/popups/TaskAddPopup'
import TaskTemplatesSelect from 'components/TaskTemplatesSelect'
import { Grid } from '@mui/material'
import Layout from 'components/Layout/Layout'
import ListAction from 'features/Actions/components/ListAction'
import { patchLocation } from 'slices/locationSlice'
import {
	createTask,
	initializeTasks,
	updateTask,
	deleteTask,
	loadTemplateIntoTasks,
} from 'slices/tasksSlice'
import { initializeTemplates } from 'slices/templateSlice'
import { emailTaskAssignment, emailTaskUpdate } from 'functions/taskEmails'
import StyledTask from 'components/Task/Task.style'
import Placeholder from 'components/Placeholder'
import { patchProject } from 'slices/projectSlice'
import moment from 'moment'

const TasksContent = ({ user, location, project }) => {
	const projectId = project._id
	const $newTaskInput = useRef(null)
	const dispatch = useDispatch()

	const SERVER_BASE_URL =
		process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.mydealteams.com'

	const [openTaskPopup, setTaskOpenPopup] = useState(false)
	const [openTemplatePopup, setTemplateOpenPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('New Task')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const [newTaskInput, setNewTaskInput] = useState('')
	const [taskList, setTaskList] = useState([])
	const [subtaskList, setSubtaskList] = useState([])

	const userId = user._id

	const locationId = location._id
	const packageType = location.assetType

	const {
		entities: taskEntities,
		ids: taskIds,
		isLoading: tasksLoading,
		isInitialized: tasksInitialized,
		error: tasksError,
	} = useSelector(state => state.tasks)
	const newTasks = taskIds.map(id => taskEntities[id])

	const tasks = newTasks.filter(task => task.parentId === null && task.completed === false)
	const subtasks = newTasks.filter(task => task.parentId !== null)
	const completedTasks = newTasks.filter(task => task.parentId === null && task.completed === true)

	const {
		entities: templateEntities,
		ids: templateIds,
		isInitialized: templatesInitialized,
	} = useSelector(state => state.templates)
	const templates = templateIds.map(id => templateEntities[id])
	const taskTemplates = templates.filter(template => template.templateType === 'task')

	const currentUser = [
		{
			assignedTo: userId,
			assignee: user.name,
			email: user.email,
		},
	]

	const assignedBy = {
		id: userId,
		name: user.name,
		email: user.email,
		company: user.company,
	}

	const partners = project.partners.map(item => {
		return {
			assignedTo: item.partnerUserId._id,
			assignee: item.partnerUserId.name,
			email: item.partnerUserId.email,
			isAccepted: item.partner.isAccepted,
		}
	})

	const filteredPartnerList = partners.reduce(
		(acc, curr) => {
			if (curr.isAccepted) {
				acc.accepted = [...acc.accepted, curr]
			}
			return acc
		},
		{
			accepted: [],
		},
	)

	const allPartners = [...currentUser, ...filteredPartnerList.accepted]

	useEffect(() => {
		dispatch(
			initializeTasks({
				userid: userId,
				packageid: locationId,
				projectid: projectId,
			}),
		)
		dispatch(initializeTemplates())
	}, [dispatch, tasksInitialized])

	useEffect(() => {
		if (tasks) {
			setTaskList(tasks)
			setSubtaskList(subtasks)
		}
	}, [taskEntities])

	const cls = (className = '', addIsCompleteClass = false) => {
		let _className = `Task__${className}`
		return _className.trim()
	}

	const handleCreateTask = async newTask => {
		const newId = newTask.assignedTo

		await dispatch(
			createTask({
				packageid: locationId,
				projectid: projectId,
				parentId: newTask.parentid,
				packageType: packageType,
				createdBy: userId,
				taskName: newTask.taskName,
				description: newTask.description,
				taskStatus: newTask.taskStatus,
				assignedTo: newTask.assignedTo,
				completedBy: newTask.completedBy,
				dateStarted: newTask.dateStarted,
				dateCompleted: newTask.dateCompleted,
				completed: newTask.isComplete,
				comments: newTask.comments,
			}),
		)

		dispatch(
			initializeTasks({
				userid: userId,
				packageid: locationId,
				projectid: projectId,
			}),
		)

		if (userId !== newId) {
			var newPartner = null

			for (let i = 1; i < allPartners.length; i++) {
				if (allPartners[i].assignedTo === newId) newPartner = allPartners[i]
			}

			const assignedTo = {
				name: newPartner.assignee,
				email: newPartner.email,
			}

			const pushTask = {
				taskName: newTask.taskName,
				taskLocation: location.assetName,
				taskProject: project.projectName,
				taskDue: moment(newTask.completedBy).format('MM/DD/YYYY'),
				taskStatus: 'Open',
			}
			const pushLink = `${SERVER_BASE_URL}/locations/${locationId}/projects/${projectId}/tasks`

			swal({
				title: `Task Created!`,
				text: `You've created a new task and assigned this to ${assignedTo.name}. An email has been sent to inform them of this assignment.`,
				icon: 'success',
			})

			emailTaskAssignment(assignedBy, assignedTo, pushTask, pushLink)
		}
		setTaskOpenPopup(false)
		setRecordForEdit(null)
		dispatch(
			patchLocation({ locationId: locationId, updates: { updateNote: 'New Task(s) Added' } }),
		)
		dispatch(patchProject({ projectId: projectId, updates: { updateNote: 'New Task(s) Added' } }))
	}

	const handleUpdateTask = async updatedTask => {
		dispatch(
			await updateTask({
				_id: updatedTask.id,
				taskName: updatedTask.taskName,
				parentId: updatedTask.parentId,
				projectid: updatedTask.projectid,
				packageid: updatedTask.packageid,
				portfolioid: updatedTask.portfolioid,
				packageType: updatedTask.packageType,
				createdBy: userId,
				assignedTo: updatedTask.assignedTo,
				description: updatedTask.description,
				taskStatus: updatedTask.taskStatus,
				completedBy: updatedTask.completedBy,
				dateStarted: updatedTask.dateStarted,
				dateCompleted: updatedTask.dateCompleted,
				completed: updatedTask.isComplete,
				comments: updatedTask.comments,
			}),
		)

		await dispatch(
			initializeTasks({
				userid: userId,
				packageid: locationId,
				projectid: projectId,
			}),
		)

		setTaskOpenPopup(false)
		setRecordForEdit(null)
		dispatch(patchLocation({ locationId: locationId, updates: { updateNote: 'Task(s) Updated' } }))
		dispatch(patchProject({ projectId: projectId, updates: { updateNote: 'Task(s) Updated' } }))
	}

	const handleTasksAddNew = () => {
		const newDate = {
			currentDate: new Date(), // Today
			futureDays: 7, // Days to add
		}

		const newTaskDate = new Date(
			new Date(newDate.currentDate).setDate(newDate.currentDate.getDate() + newDate.futureDays),
		)

		const newTask = {
			_id: 0,
			assignedTo: userId,
			assignedToName: user.name,
			completedBy: newTaskDate,
		}
		setPopupTitle('New Task')
		setRecordForEdit(newTask)
		setTaskOpenPopup(true)
	}

	const handleTasksTemplates = () => {
		setTemplateOpenPopup(true)
	}

	const handleDeleteTask = async task => {
		const parentId = task.parentId
		const taskId = task.id

		await dispatch(deleteTask(taskId))
		if (parentId) {
			const newTaskList = subtaskList.filter(task => task._id !== taskId)
			setSubtaskList(newTaskList)
		} else {
			const newTaskList = taskList.filter(task => task._id !== taskId)
			setTaskList(newTaskList)
		}

		await dispatch(
			initializeTasks({
				userid: userId,
				packageid: locationId,
				projectid: projectId,
			}),
		)
	}

	const handleTamplateLoad = async (templateId, resetForm) => {
		const template = {
			userId: userId,
			packageid: locationId,
			projectid: projectId,
			packageType: packageType,
			templateId: templateId,
		}

		await dispatch(loadTemplateIntoTasks(template))

		resetForm()
		setTemplateOpenPopup(false)

		dispatch(
			initializeTasks({
				userid: userId,
				packageid: locationId,
				projectid: projectId,
			}),
		)

		setTaskList(tasks)
		setNewTaskInput('')
		if ($newTaskInput?.current) $newTaskInput.current.focus()

		dispatch(
			patchLocation({ locationId: locationId, updates: { updateNote: 'New Task(s) Added' } }),
		)
	}

	const renderTaskList = taskList =>
		taskList.map(task => (
			<Task
				key={task._id}
				id={task._id}
				userId={task.createdBy._id}
				packageid={task.packageid}
				projectid={task.projectid}
				portfolioid={null}
				packageType={packageType}
				title={task.taskName}
				taskName={task.taskName}
				description={task.description}
				taskStatus={task.taskStatus}
				dueDate={task.completedBy}
				completedBy={task.completedBy}
				dateStarted={task.dateStarted}
				dateCompleted={task.dateCompleted}
				isComplete={task.completed}
				assignedTo={task.assignedTo._id}
				assignedToName={task.assignedTo.name}
				firstName={task.assignedTo.firstName}
				image={task.assignedTo.image}
				assignedToEmail={task.assignedTo.email}
				partners={allPartners}
				comments={task.comments}
				user={user}
				location={location}
				project={project}
				handleCreateTask={handleCreateTask}
				handleDeleteTask={handleDeleteTask}
				handleUpdateTask={handleUpdateTask}
			>
				{subtaskList
					.filter(subtask => subtask.parentId === task._id)
					.map(subtask => (
						<Task
							key={subtask._id}
							id={subtask._id}
							userId={subtask.createdBy._id}
							packageid={subtask.packageid}
							projectid={subtask.projectid}
							portfolioid={null}
							packageType={packageType}
							title={subtask.taskName}
							taskName={subtask.taskName}
							description={subtask.description}
							taskStatus={subtask.taskStatus}
							dueDate={subtask.completedBy}
							completedBy={subtask.completedBy}
							dateStarted={subtask.dateStarted}
							dateCompleted={subtask.dateCompleted}
							isComplete={subtask.completed}
							assignedTo={subtask.assignedTo._id}
							assignedToName={subtask.assignedTo.name}
							firstName={subtask.assignedTo.firstName}
							image={subtask.assignedTo.image}
							assignedToEmail={subtask.assignedTo.email}
							partners={allPartners}
							comments={subtask.comments}
							user={user}
							location={location}
							project={project}
							handleCreateTask={handleCreateTask}
							handleUpdateTask={handleUpdateTask}
							handleDeleteTask={handleDeleteTask}
							// subtask-only props
							parentId={task._id}
							seq={subtask.seq}
						/>
					))}
			</Task>
		))

	if (tasksLoading) return <Spinner />
	if (tasksError) return <Message variant="danger">{tasksError}</Message>

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<StyledTask>
						<div className={cls('header', true)}>
							<div className={cls('title')}>{`Task`}</div>
							<div className={cls('status')}>{'Status'}</div>
							<div className={cls('date')}>{`Task Due`}</div>
							<div className={cls('assignee')}>{'Assigned To'}</div>
						</div>
					</StyledTask>
					{tasks.length === 0 ? (
						<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
							<div>
								<img
									className="details__avatar"
									src={EmptyLogo}
									alt=""
									onClick={handleTasksAddNew}
								/>
							</div>
							<div>
								<Button onClick={handleTasksAddNew} variant="success" isLarge>
									<FaPlus /> Start Here to Add a NewTask
								</Button>
							</div>
						</Placeholder>
					) : (
						renderTaskList(tasks)
					)}
					{completedTasks.length > 0 && (
						<div
							style={{
								margin: '32px 0 41px',
								textAlign: 'center',
								borderBottom: '1px solid #ccc',
							}}
						>
							<h5
								style={{
									margin: '0',
									position: 'relative',
									top: '9px',
									fontSize: '.75rem',
									display: 'inline-block',
									backgroundColor: '#fff',
									color: '#a2a2a2',
									padding: '0 9px',
								}}
							>
								Completed
							</h5>
						</div>
					)}
					{renderTaskList(completedTasks)}
				</Grid>
				<Grid item xs={3}>
					<Layout.SidebarItem title="Actions" hasNoMaxHeight>
						<ul>
							<li className="actionList__item">
								<ListAction
									handleAction={handleTasksAddNew}
									actionLable={'Add New Task'}
									actionIcon={<FaPlus />}
								/>
							</li>
							<li className="actionList__item">
								<ListAction
									handleAction={handleTasksTemplates}
									actionLable={'Task Templates'}
									actionIcon={<FaFileAlt />}
								/>
							</li>
						</ul>
					</Layout.SidebarItem>
				</Grid>
			</Grid>
			{openTaskPopup ? (
				<Popup title={popupTitle} openPopup={openTaskPopup} setOpenPopup={setTaskOpenPopup}>
					<TaskAddPopup
						partners={allPartners}
						addTask={handleCreateTask}
						recordForEdit={recordForEdit}
					/>
				</Popup>
			) : null}

			{openTemplatePopup ? (
				<Popup
					title="Task Templates"
					openPopup={openTemplatePopup}
					setOpenPopup={setTemplateOpenPopup}
				>
					<TaskTemplatesSelect
						taskTemplates={taskTemplates}
						handleTamplateLoad={handleTamplateLoad}
					/>
				</Popup>
			) : null}
		</div>
	)
}

export default TasksContent
