import React, { useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SidebarArchiveLibraryTop from './sidebars/SidebarArchiveLibraryTop'
import RenderSubRoutes from 'components/RenderSubRoutes'
import getArchiveLibraryRoutes from './getRoutes/getArchiveLibraryRoutes'
import Layout from 'components/Layout'
import { BusinessCard } from 'components/Card'
import routes from 'constants/routes'
import useMediaQuery from 'hooks/useMediaQuery'
import * as _ from 'styles/helpers'
import { initializeActiveLocations } from 'slices/locationSlice'
import { initializeUsers } from 'slices/usersSlice'

const Locations_Show = () => {
	const dispatch = useDispatch()
	const params = useParams()
	const { id } = params
	const history = useHistory()

	const currentRoute = useLocation()
	// const subRoutes = getArchiveLibraryRoutes(id)

	const pathArray = currentRoute.pathname.split('/')
	const isPartnerPage = pathArray[pathArray.length - 1] === 'partners'

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const { isLoading, isInitialized, location } = useSelector(state => {
		return {
			isLoading: state.locations.isLoading,
			isInitialized: state.locations.isInitialized,
			location: state.locations.entities[id],
		}
	})

	const { success: userListInitialized } = useSelector(state => {
		return {
			success: state.userList.isInitialized,
		}
	})

	useEffect(() => {
		if (!isInitialized) {
			dispatch(initializeActiveLocations())
		}
		if (!userListInitialized) {
			dispatch(initializeUsers())
		}
		// dispatch(initializeProjects({ locationId: id }))
	}, [dispatch, id, isInitialized, userListInitialized])

	const partners = location?.partners
	const assetOwner = location?.owner

	const isMobile = useMediaQuery(_.MQ.before_nav)

	if (!location || isLoading) {
		return null
	}

	const subRoutes = getArchiveLibraryRoutes()

	return (
		<Layout>
			<Layout.SecondaryNav
				links={subRoutes}
				backLink={{ title: 'Locations', link: routes.LOCATIONS }}
			/>
			<Layout.Page>
				<Layout.Sidebar isOffset>
					{location ? (
						<>
							<SidebarArchiveLibraryTop location={location} />
						</>
					) : null}
				</Layout.Sidebar>
				<Layout.Body>
					{isMobile && isLoading === false && (
						<div style={{ marginBottom: '24px' }}>
							<BusinessCard
								isHorizontal
								image={location?.image}
								name={location?.assetName}
								address1={location?.address}
								address2={`${location?.city}, ${location?.stateProvince}`}
							/>
						</div>
					)}
					<RenderSubRoutes routes={subRoutes} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Locations_Show
