import React from 'react'
import { Button as MuiButton } from '@mui/material'
import Button from 'components/Button'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(0.5),
	},
	label: {
		textTransform: 'none',
	},
}))

export default function TWControlsButton(props) {
	const { text, size, color, variant, onClick, disabled, ...other } = props
	const classes = useStyles()

	// return (
	// 	<MuiButton
	// 		size={size || 'medium'}
	// 		color={color || 'primary'}
	// 		{...other}
	// 		classes={{ root: classes.root, label: classes.label }}
	// 	>
	// 		{text}
	// 	</MuiButton>
	// )

	return (
		<Button
			size={size || 'medium'}
			variant={variant || 'primary'}
			isOutline={variant === 'outlined'}
			disabled={disabled}
			onClick={onClick}
		>
			{text}
		</Button>
	)
}
