import { createGlobalStyle } from 'styled-components'
/*
	meyer reset
*/
// import './base/reset.css'
/*
sanitize reset
*/
import 'sanitize.css'
// import 'sanitize.css/forms.css'
// import 'sanitize.css/typography.css'

import './base/google-fonts.css'
import './base/base.css'
import * as _ from './helpers'

/*
	TODO - Remove all of these
*/
import '../styles/css/Card.css'
import '../styles/css/CenterNoteFeed.css'
import '../styles/css/CenterSidebar.css'
import '../styles/css/Feed.css'
import '../styles/css/Form.css'
import '../styles/css/Icon.css'
import '../styles/css/InputOption.css'
import '../styles/css/Location.css'
import '../styles/css/LocationNote.css'
import '../styles/css/LocationNoteFeed.css'
import '../styles/css/LocationScreen.css'
import '../styles/css/Map.css'
import '../styles/css/Popup.css'
import '../styles/css/Post.css'
import '../styles/css/Sidebar.css'
import '../styles/css/ThirdParty.css'

// dedupe and consolidate
import '../styles/css/_consolidated.css'

/*
	TODO: remove Bootstrap in favor of sanitize
*/
// import '../styles/css/bootstrap.min.css'

export const GlobalStyles = createGlobalStyle`
	/* Display current media query on the screen */
	${_.MediaQueryHelper}

	body {
		font-family: ${_.FONTS.base_font_family};
		font-weight: ${_.FONTS.regular};
		line-height: 1.5;
		color: ${_.COLORS.foreground};
		background-color: ${_.COLORS.background};
	}

	body,
	#root {
		${_.MIXINS.full_height}
	}

	a {
		color: ${_.COLORS.tradewind_blue_a11y};
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

  button:focus {
    outline: 1px dotted;
    outline: 1px auto -webkit-focus-ring-color;
  }

	.mod {
		${_.MIXINS.mod};
	}

  h1, h2, h3, h4, h5, h6 {
    ${_.TYPOGRAPHY.heading}
  }

  h1 {
    line-height: 1.25;
  }

  h2 {
    line-height: 1.125;
  }

  h3 {
    line-height: 1.5;
  }

	.h1 {
		${_.TYPOGRAPHY.h1}
	}
	.h2 {
		${_.TYPOGRAPHY.h2};
		color: ${_.COLORS.blue_400};
		font-weight:600;

	}
	.h3 {
		${_.TYPOGRAPHY.h3}
		color: darkgray;
		/* color: ${_.COLORS.blue_400}; */
		font-weight:400;
	}

  /* TEMP - create a sub Layout component  */
  /* .feed {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  } */

  /* TEMP - fixes annoying HMR + inspector issue */
  iframe {
    pointer-events: none;
  }

  /* TEMP - fixes empty input placeholder text */
  .MuiInputLabel-outlined.MuiInputLabel-outlined {
    transform: translate(14px, 10px) scale(1);
  }

	/* TEMP - remove bottom border on form inputs */
	.feed_input > form > input {
		box-shadow: none !important;
	}

	/* TEMP */
	.MuiPopover-root,
	.MuiDialog-root {
		z-index: 9999!important;
	}

	/* TEMP */
	.TEMP_PAGE_HEADER {
		display: flex;
    justify-content: space-between;
    align-items: center;
		min-height: 56px;
		margin: 0 0 32px;

		h2 {
			margin: 0;
			font-size: 36px;
			font-weight: 500;
		}

		button {
			margin: 0;
		}
	}

	.TEMP_SIDEBAR_HEADER {
		${_.MIXINS.mod};
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: ${_.COLORS.white};
		background-color: ${_.COLORS.tradewind_blue};

		h2 {
			font-size: 18px;
		}
	}

  /* TEMP */
  .TEMP_USER_REVIEW_POPUP {
    min-width: 544px;

    .body {
      display: flex;
      justify-content: center;
    }
    .avatar {
      margin-right: 20px
    }
    .name {
      margin-bottom: 0;
      font-size: 1.375rem;
      font-weight: 600;
    }
    .title {
      font-size: 18px;
    }
  }

  /* TEMP */
  .new-task-input {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding-left: 6px;
    padding-right: 6px;
    width: 100%;
    max-width: 200px;
    max-height: 32px;
  }

  /* Chat MUI overrides */
  .MuiAvatarGroup-avatar.MuiAvatarGroup-avatar {
		border: none;
		box-shadow: 0 0 0 2px #fff;
		border-radius: 100%;
		margin-left: -24px;
		margin-right: 12px;
	}

  /* MUI helpers */
  .secondary-action {
    pointer-events: none;
  }
`
