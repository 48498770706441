import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authService } from 'services'
import axios from 'axios'
import { emailUpdateInviter } from 'functions/loginRegisterEmails'

const SLICE_NAME = 'auth'
// Me, myself and I

const localUserInfo = localStorage.getItem('userInfo')

const initialState = {
	loading: false,
	isInitialized: false,
	userInfo: localUserInfo ? JSON.parse(localUserInfo) : null,
	error: null,
}

const getInitialState = () => {
	const _localUserInfo = localStorage.getItem('userInfo')
	return {
		loading: false,
		isInitialized: false,
		userInfo: _localUserInfo ? JSON.parse(_localUserInfo) : null,
		error: null,
	}
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const login = createAsyncThunk(
	`${SLICE_NAME}/login`,
	async (payload: { email: string; password: string }) => {
		const data = await authService().login(payload)
		if (data) {
			localStorage.setItem('userInfo', JSON.stringify(data))
			return data
		}
	},
)

export const getUserDetails = createAsyncThunk(
	`${SLICE_NAME}/getUserDetails`,
	async (id, { getState, rejectWithValue }) => {
		const { userInfo } = getInitialState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const data = await axios.get(`/api/users/profile`, config).then(res => {
			return res.data
		})

		if (data) {
			localStorage.removeItem('userInfo')
			localStorage.setItem('userInfo', JSON.stringify(data))
			return data
		} else {
			return null
		}
	},
)
export const patchUser = createAsyncThunk(
	`${SLICE_NAME}/patchUser`,
	async (payload: any, { getState, rejectWithValue }) => {
		try {
			const data = await axios
				.patch(`/api/users/patch`, payload, prepConfig(getState))
				.then(res => res.data)

			if (data) {
				localStorage.setItem('userInfo', JSON.stringify(data))

				return data
			} else {
				return null
			}
		} catch (error) {
			return rejectWithValue('Unable to patch user')
		}
	},
)

export const updateUser = createAsyncThunk(
	`${SLICE_NAME}/updateUser`,
	async (payload: any, { getState, rejectWithValue }) => {
		const user = payload
		console.log(`user: `, user)

		const { userInfo } = getInitialState()

		const config = {
			headers: {
				// 'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const data = await axios.put(`/api/users/update`, user, config).then(res => {
			return res.data
		})

		if (data) {
			localStorage.setItem('userInfo', JSON.stringify(data))
			return data
		} else {
			return null
		}
	},
)

export const registerNewUser = createAsyncThunk(
	`${SLICE_NAME}/registerNewUser`,
	async (payload: { name: string; firstName: string; email: string; password: string }) => {
		const { name, firstName, email, password } = payload

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const data = await axios
			.post('/api/users', { name, firstName, email, password }, config)
			.then(res => {
				return res.data
			})

		if (data) {
			const inviter = data.inviter
			if (inviter) {
				const invited = {
					name: name,
					firstName: firstName,
					email: email,
				}
				emailUpdateInviter(invited, inviter)
			}
			localStorage.setItem('userInfo', JSON.stringify(data))

			return data
		} else {
			return null
		}
	},
)

const authSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {
		logout: state => {
			localStorage.removeItem('userInfo')
			state.userInfo = null
			// localStorage.removeItem('companyInfo')
			// localStorage.removeItem('userCompany')
		},
	},

	extraReducers: {
		[login.pending as any]: () => ({ ...initialState }),

		[login.fulfilled as any]: (state, action) => ({
			...state,
			userInfo: action.payload,
			loading: false,
		}),
		[login.rejected as any]: (state, action) => ({
			...state,
			loading: false,
			error: action.error,
		}),
		[getUserDetails.pending as any]: state => {
			state.isInitialized = false
			state.loading = true
		},

		[getUserDetails.fulfilled as any]: (state, action) => {
			const user = action.payload
			return {
				loading: false,
				isInitialized: true,
				userInfo: user,
			}
		},

		[patchUser.pending as any]: () => ({ ...initialState }),

		[patchUser.fulfilled as any]: (state, action) => ({
			...state,
			userInfo: action.payload,
			loading: false,
			isInitialized: false,
		}),
		[patchUser.rejected as any]: (state, action) => ({
			...state,
			loading: false,
			error: action.error,
		}),

		[updateUser.pending as any]: () => ({ ...initialState }),

		[updateUser.fulfilled as any]: (state, action) => ({
			...state,
			userInfo: action.payload,
			loading: false,
			isInitialized: false,
		}),
		[updateUser.rejected as any]: (state, action) => ({
			...state,
			loading: false,
			error: action.error,
		}),

		[registerNewUser.pending as any]: () => ({ ...initialState }),

		[registerNewUser.fulfilled as any]: (state, action) => ({
			...state,
			userInfo: action.payload,
			loading: false,
		}),

		[registerNewUser.rejected as any]: (state, action) => ({
			...state,
			loading: false,
			error: action.error,
		}),
	} as any,
})

export const { logout } = authSlice.actions
export default authSlice.reducer
