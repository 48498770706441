import React, { useState, useEffect, useRef } from 'react'
import { Grid, IconButton, TableBody, TableCell, TableRow } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'
import useTableNoPage from 'components/controls/useTableNoPage'
import { GetApp as GetAppIcon } from '@mui/icons-material'
import Popup from 'components/Popup'
import swal from 'sweetalert'
import TemplateSiteDetailView from '../TemplateSiteDetailView'

const headerCells = [
	{ id: 'templateName', label: 'Template' },
	{ id: 'description', label: 'Description' },
	{ id: 'download', label: 'Download' },
]

const TaskTemplatesSelect = ({ detailTemplates, handleTemplateLoad }) => {
	const validate = () => {
		let temp = { ...errors }

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}
	const [openSiteDetailPopup, setOpenSiteDetailPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('Template Site Details')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		// initialValues,
		true,
		validate,
	)

	const records = detailTemplates
	const { TblContainer, TblHeader, TblPagination } = useTableNoPage(
		records,
		headerCells,
		// filterFn,
	)

	const openInPopup = task => {
		setRecordForEdit(task)
		setOpenSiteDetailPopup(true)
	}

	const downloadHandler = template => {
		const id = template._id
		swal({
			text: `This will download the ${template.templateName} template to your Site Details list.`,
			buttons: ['Cancel', 'Ok!'],
		}).then(value => {
			if (value) {
				handleTemplateLoad(id, resetForm)
				swal(
					'Downloaded!',
					`The ${template.templateName} template has been downloaded to your Site Details list.`,
					'success',
				)
			}
		})
	}

	return (
		<Form>
			<Grid container>
				<Grid item>
					<div>
						<TblContainer>
							<TblHeader />
							<TableBody>
								{detailTemplates.map(template => (
									<TableRow key={template._id}>
										<TableCell
											onClick={() => {
												setPopupTitle('Template Site Details')
												openInPopup(template)
											}}
										>
											{template.templateName}
										</TableCell>
										<TableCell
											onClick={() => {
												setPopupTitle('Template Site Details')
												openInPopup(template)
											}}
										>
											{template.description}
										</TableCell>
										<TableCell>
											<IconButton
												className="feed__icon"
												color="primary"
												size="small"
												onClick={() => downloadHandler(template)}
											>
												<GetAppIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</TblContainer>
					</div>
				</Grid>
			</Grid>
			{/* {openSiteDetailPopup ? (
				<Popup
					title={popupTitle}
					openPopup={openSiteDetailPopup}
					setOpenPopup={setOpenSiteDetailPopup}
				>
					<TemplateSiteDetailView recordForEdit={recordForEdit} />
				</Popup>
			) : null} */}
		</Form>
	)
}

export default TaskTemplatesSelect
