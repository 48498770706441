import React, { useState, useEffect } from 'react'
import Spinner from 'components/Spinner'
import AddImage from 'images/add_image.png'
import AltRetailerLogo from 'images/vr-new-retail_3.png'
import { Avatar, Grid } from '@mui/material'
import FileUploader from 'components/FileUploader'
import { FaImage, FaRegSmileWink } from 'react-icons/fa'
import styled from 'styled-components'
import TWControls from 'components/controls/TWControls'
import { Form, useForm } from 'components/controls/useForm'
import * as _ from 'styles/helpers'

// const API_PATH = process.env.REACT_APP_CLOUDINARY_PATH
// const API_SERVER = process.env.REACT_APP_CLOUDINARY_SERVER

const Location_Edit = ({ editLocation, recordForEdit }) => {
	const initialValues = {
		...recordForEdit,
	}

	const locationTypes = [
		{ id: 1 },
		{ selectValue: 'Commercial Center' },
		{ id: 2 },
		{ selectValue: 'Commercial Location' },
		{ id: 3 },
		{ selectValue: 'Retail Center' },
		{ id: 4 },
		{ selectValue: 'Retail Location' },
	]

	const [image, setImage] = useState(false)
	const [picLoading, setPicLoading] = useState(false)
	const [picLoaded, setPicLoaded] = useState(false)

	const validate = () => {
		let temp = { ...errors }
		temp.assetName = values.assetName ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()

		console.log(`values: `, values)

		if (validate()) {
			editLocation(values)
		}
	}

	useEffect(() => {
		if (recordForEdit != null) setValues({ ...recordForEdit })
	}, [recordForEdit, setValues])

	const uploadImage = async info => {
		setPicLoading(true)
		setImage(info.originalUrl)
		values.image = info.originalUrl
		setPicLoading(false)
	}

	return (
		<StyledAssetEdit>
			<Form onSubmit={submitHandler}>
				{/* <Card
				className="UserProfile__Card"
				header={{
					title: `Edit: ${values.assetName}`,
				}}
			> */}
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TWControls.Input
							size="small"
							name="assetName"
							label="Location Name"
							value={values.assetName}
							onChange={handleInputChange}
							// error={errors.assetName}
						/>
					</Grid>
					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="retailer"
							label="Retailer"
							value={values.retailer}
							onChange={handleInputChange}
						/>
					</Grid>
					<Grid item xs={3}>
						<TWControls.SelectType
							className="feed_select"
							// size="small"
							name="locationType"
							label="Location Type"
							value={values.locationType}
							onChange={handleInputChange}
							options={locationTypes}
						/>
					</Grid>
					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="address"
							label="Address"
							value={values.address}
							onChange={handleInputChange}
						/>
					</Grid>
					<Grid item xs={6}>
						<TWControls.Input
							size="small"
							name="assetLink"
							label="Website"
							value={values.assetLink}
							onChange={handleInputChange}
						/>
					</Grid>
					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="phoneNumber"
							label="Phone Number"
							value={values.phoneNumber}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="city"
							label="City"
							value={values.city}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="stateProvince"
							label="State/Prov"
							value={values.stateProvince}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="postalCode"
							label="Postal Code"
							value={values.postalCode}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="country"
							label="Country"
							value={values.country}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="latitude"
							label="Latitude"
							value={values.latitude}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="longitude"
							label="Longitude"
							value={values.longitude}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="totalSqft"
							label="SQFT"
							value={values.totalSqft}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="apn"
							label="APN"
							value={values.apn}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.CheckBox
							size="small"
							name="isActive"
							label="Active?"
							value={values.isActive}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.CheckBox
							size="small"
							name="isInFleet"
							label="Fleet Location?"
							value={values.isInFleet}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.CheckBox
							size="small"
							name="isPOIOnly"
							label="POI Only?"
							value={values.isPOIOnly}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.CheckBox
							size="small"
							name="isTenantOnly"
							label="Tenant Only?"
							value={values.isTenantOnly}
							onChange={handleInputChange}
						/>
					</Grid>
				</Grid>
				<div className="AssetEdit__Asset">
					{picLoading ? (
						<Spinner />
					) : values.image ? (
						<img className="AssetEdit__Image" src={values.image} alt="" />
					) : (
						<img className="AssetEdit__Image" src={AltRetailerLogo} alt="" />
					)}
					<FileUploader onChange={info => uploadImage(info)}>
						<FaImage /> {values.image ? `Update Image` : `Add Image`}
					</FileUploader>
				</div>

				<Grid item xs={12}>
					<div className="AssetEdit__Actions">
						<TWControls.Button size="small" type="submit" text="Update" />
					</div>
				</Grid>
				{/* </Card> */}
			</Form>
		</StyledAssetEdit>
	)
}

const StyledAssetEdit = styled.div`
	max-width: ${_.rem(960)};
	margin: 0 auto;

	.AssetEdit {
		&__Card {
			h2 {
				overflow: hidden;
				white-space: nowrap;
			}
		}

		&__password-buttton {
			${_.MIXINS.vhc}
			margin: ${_.rem(16)} 0 ${_.rem(24)};
		}

		&__Asset,
		&__Actions {
			${_.MIXINS.mod}
			${_.MIXINS.vhc}
			flex-direction: column;
			padding: ${_.rem(16)};
		}

		&__Image {
			width: ${_.rem(250)};
			margin-bottom: ${_.rem(10)};
			border-radius: ${_.rem(5)};
			border: ${_.rem(2)} solid #0073e6;
			margin-bottom: ${_.rem(16)};
		}
	}

	input {
		font-size: ${_.rem(18)};
	}

	.input-group {
		${({ isMobile }) =>
			!isMobile &&
			`
      display: flex;
      gap: ${_.rem(16)};
    `}
	}

	.input-group-title {
		margin-top: ${_.rem(16)};
		margin-bottom: ${_.rem(16)};
		font-weight: 400;
		font-size: ${_.rem(14)};
		color: ${_.COLORS.gray_500};
	}
`

export default Location_Edit
