import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'components/Card'
import styled from 'styled-components'
import { initializeMyPartners } from 'slices/myPartnersSlice'
import Layout from 'components/Layout'
import * as _ from 'styles/helpers'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { FaUsers } from 'react-icons/fa'
import Message from './Message'

function SidebarMyActivePartners() {
	const dispatch = useDispatch()
	const {
		entities: myPartners,
		ids: myPartnerIds,
		isInitialized: myPartnersInitialized,
		isLoading,
		error,
	} = useSelector(state => state.myPartners)
	const myPartnersList = myPartnerIds.map(id => myPartners[id])

	useEffect(() => {
		if (!myPartnersInitialized) {
			dispatch(initializeMyPartners())
		}
	}, [dispatch, myPartnersList])

	return (
		<Layout>
			<Layout.Sidebar>
				<StyledSidebar>
					<div className="SidebarContent">
						<Card className="SidebarContent__card SidebarContent__card--partners">
							<div className="SidebarContent__card-header">
								<h2>
									<FaUsers /> Partnerships
								</h2>
							</div>
							<div className="SidebarContent__card-active-body">
								{/* Mike: TODO: Set initial state for partners to empty array in redux */}

								{isLoading ? (
									<Layout.SidebarItemLoading />
								) : error ? (
									<Message variant="danger">{error}</Message>
								) : (
									myPartnersList &&
									myPartnersList.length > 0 && (
										<List dense disablePadding>
											{myPartnersList.map((partner, index) =>
												partner.isAccepted ? (
													<ListItem key={index}>
														<ListItemAvatar>
															<Avatar src={partner.partner.image} />
														</ListItemAvatar>
														<ListItemText
															primary={partner.partner.name}
															secondary={partner.partner.company}
														/>
													</ListItem>
												) : (
													<ListItem key={index}>
														<ListItemAvatar>
															<Avatar src={partner.partner.image} />
														</ListItemAvatar>
														<ListItemText primary={partner.partner.name} secondary={`pending...`} />
													</ListItem>
												),
											)}
										</List>
									)
								)}
							</div>
						</Card>
					</div>
				</StyledSidebar>
			</Layout.Sidebar>
		</Layout>
	)
}

const StyledSidebar = styled.div`
	.SidebarHeader {
		${_.MIXINS.vc}
		margin-bottom: ${_.rem(32)};

		&__avatar {
			width: ${_.rem(68)};
			height: ${_.rem(68)};
			margin-right: ${_.rem(24)};
			border: ${_.rem(4)} solid #0000001a;
		}

		&__greeting {
			${_.MIXINS.mod}
			display: flex;
			justify-content: center;
			flex-direction: column;

			&__username {
				${_.TYPOGRAPHY.p}
			}
			&__title {
				${_.TYPOGRAPHY.h1}
			}
		}
	}

	.SidebarContent {
		display: grid;
		grid-gap: ${_.rem(24)};
		grid-template-columns: repeat(12, [col] 1fr);

		&__card {
			border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};
		}

		&__card-header {
			${_.MIXINS.vc};
			justify-content: space-between;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(22)};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(11)};
				font-style: italic;
			}
		}

		&__card-active-body {
			height: 100%;
			min-height: ${_.rem(300)};
			height: ${_.rem(335)};
			overflow-y: scroll;
		}

		&__card-pending-body {
			height: 100%;
			height: ${_.rem(245)};
			overflow-y: scroll;
		}

		&__card-empty {
			${_.MIXINS.vhc};
			height: 100%;

			h3 {
				font-size: ${_.rem(16)};
				font-weight: 300;
				color: rgba(0, 0, 0, 0.54);
			}
		}

		&__card {
			min-height: ${_.rem(188)};
			margin-bottom: ${_.rem(24)};

			&--partners {
				grid-column: col / span 12;
			}

			&-content {
				padding: 0 ${_.rem(12)};
			}
		}
	}
`

export default SidebarMyActivePartners
