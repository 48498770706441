import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import screens from 'screens'
import routes from 'constants/routes'

const AppRoutes = () => (
	<Switch>
		{/* Login/Register */}
		<Route path={[
			routes.LOGIN,
			routes.REGISTER,
			routes.FORGOT_PASSWORD,
			routes.CHANGE_PASSWORD,
			routes.CONFIRM_PASS_EMAIL,
			routes.EMAIL_CONFIRM
		]} component={screens.LoginScreen} />
		{/* <Route path={routes.EMAIL_CONFIRM} component={screens.EmailConfirm} /> */}

		<PrivateRoute path={routes.HOME} exact component={screens.Home} />

		{/* LocationAdminTools */}
		<PrivateRoute path={routes.LOCATION_ADMIN_TOOLS} exact component={screens.LocationAdminTools_Index} />
		<PrivateRoute path={`${routes.LOCATION_ADMIN_TOOLS}/tools/:toolType`} component={screens.LocationAdminTools_Show} />

		{/* Comunity */}
		<PrivateRoute path={routes.CHAT} exact component={screens.ChatPage} />

		{/* Comunity */}
		<PrivateRoute path={routes.COMMUNITY} exact component={screens.Community_Page} />

		{/* Locations */}
		<PrivateRoute path={routes.LOCATIONS} exact component={screens.Locations_Index} />
		<PrivateRoute path={`${routes.LOCATIONS}/new`} exact component={screens.Locations_New} />
		<PrivateRoute path={`${routes.LOCATIONS}/:id/projects/:projectId`} component={screens.Locations_Project_Show} />
		<PrivateRoute path={`${routes.LOCATIONS}/:id`} component={screens.Locations_Show} />

		{/* Portfolios */}
		<PrivateRoute path={routes.PORTFOLIOS} exact component={screens.Portfolios_Index} />
		<PrivateRoute path={`${routes.PORTFOLIOS}/:id`} component={screens.Portfolios_Show} />

		{/* Partner Apps */}
		<PrivateRoute path={routes.PARTNERAPPS} exact component={screens.PartnerApps_Index} />
		<PrivateRoute path={`${routes.PARTNERAPPS}/:id`} component={screens.PartnerApps_Show} />

		{/* Projects Index*/}
		<PrivateRoute path={routes.PROJECTS} exact component={screens.Projects_Index} />

		{/* Tasks */}
		<PrivateRoute path={routes.TASKS} exact component={screens.Tasks_Index} />
		<PrivateRoute path={`${routes.TASKS}/:id`} component={screens.Tasks_Show} />

		{/* Templates */}
		<PrivateRoute path={routes.TEMPLATES} exact component={screens.Templates_Index} />
		<PrivateRoute path={`${routes.TEMPLATES}/:id/task`} component={screens.TemplateTask_Show} />
		<PrivateRoute path={`${routes.TEMPLATES}/:id/sitedetail`} component={screens.TemplateSiteDetail_Show} />

		{/* Partners */}
		<PrivateRoute path={routes.PARTNERS} exact component={screens.Partners_Index} />

		{/* Admin */}
		<PrivateRoute path={routes.USER_PROFILE} exact component={screens.UserProfile} />
		<PrivateRoute path={routes.COMPANY_PROFILE} exact component={screens.Company_Profile} />
		{/* <PrivateRoute path={`${routes.ADMIN}/users`} exact component={screens.Users_Index} /> */}
		{/* <PrivateRoute path={`${routes.ADMIN}/users/:id/edit`} exact component={screens.Users_Edit} /> */}

		{/* 404 Not Found */}
		<Route component={screens.FourOhFour} />
	</Switch>
)

export default AppRoutes
