import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Avatar, TableBody, TableCell, TableRow, IconButton } from '@mui/material'
import { HighlightOff as HighlightOffIcon } from '@mui/icons-material'
import { RiCloseCircleLine } from 'react-icons/ri'
import StyledLocation from 'components/Location/Location.style'

const PortfolioLocationsTable = ({
	locations,
	locationRemove,
	openInMap,
	TblContainer,
	TblHeader,
	TblPagination,
	recordCount,
	userId,
	isOwner,
}) => {
	const locationsMapped = useSelector(state => state.locations.entities)

	return (
		<StyledLocation tabIndex={0}>
			<TblContainer className="topPage__tableContainer">
				<TblHeader />
				<TableBody>
					{locations.map(portfolioLocation => {
						const location = locationsMapped[portfolioLocation.location]
						if (!location) return <></>
						return (
							<TableRow key={location._id}>
								<TableCell>
									<Avatar src={location.image} />
								</TableCell>
								<TableCell>
									<Link to={`/locations/${location._id}`}>{location.assetName}</Link>
								</TableCell>
								<TableCell onClick={() => openInMap(location)}>
									<Link to="#">{location.address}</Link>
								</TableCell>
								<TableCell>{location.city}</TableCell>
								<TableCell>{location.stateProvince}</TableCell>
								<TableCell>{location.postalCode}</TableCell>
								<TableCell>
									{isOwner && (
										<button
											className="Location__delete-button"
											onClick={() => locationRemove(location)}
										>
											<RiCloseCircleLine />
										</button>
									)}
								</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</TblContainer>
			{recordCount > 4 ? <TblPagination /> : null}
		</StyledLocation>
	)
}

export default PortfolioLocationsTable
