import emailjs from 'emailjs-com'

const userId = process.env.REACT_APP_EMAILJS_USER_ID
const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID

const emailInviteExistingUser = ({ fromUser, toUser, pushLink }) => {
	const templateId = 'template_1yahsw9'

	const templateParams = {
		fromName: fromUser.name,
		toName: toUser.name,
		firstName: toUser.firstName,
		toEmail: toUser.email,
		locationLink: pushLink,
	}

	emailjs.send(serviceId, templateId, templateParams, userId).catch(err => console.log(err))
}

const OLD_emailInviteExistingUser = ({ fromUser, toUser, location, pushLink }) => {
	const templateId = 'template_0kyvgya'

	const templateParams = {
		fromName: fromUser.name,
		fromEmail: fromUser.email,
		toName: toUser.name,
		firstName: toUser.firstName,
		toEmail: toUser.email,
		locationName: location.assetName,
		locationCity: location.city,
		locationStateProv: location.stateProvince,
		locationLink: pushLink,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		// .then(res => {
		// 	console.log(res)
		// })
		.catch(err => console.log(err))
}

const emailReInviteExistingUser = ({ fromUser, toUser, location, pushLink }) => {
	const templateId = 'template_dx9u9qn'

	const templateParams = {
		fromName: fromUser.name,
		fromEmail: fromUser.email,
		toName: toUser.name,
		firstName: toUser.firstName,
		toEmail: toUser.email,
		locationName: location.assetName,
		locationCity: location.city,
		locationStateProv: location.stateProvince,
		locationLink: pushLink,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		// .then(res => {
		// 	console.log(res)
		// })
		.catch(err => console.log(err))
}

const emailReInviteExistingUsercc = ({ fromUser, toUser, location, pushLink }) => {
	const templateId = 'template_sos3q2h'

	const templateParams = {
		fromName: fromUser.name,
		fromEmail: fromUser.email,
		toName: toUser.name,
		firstName: toUser.firstName,
		toEmail: toUser.email,
		locationName: location.assetName,
		locationCity: location.city,
		locationStateProv: location.stateProvince,
		locationLink: pushLink,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		// .then(res => {
		// 	console.log(res)
		// })
		.catch(err => console.log(err))
}

const emailInviteNewUser = (fromUser, toUser, location, pushLink) => {
	const templateId = 'template_9qrus0b'

	const templateParams = {
		fromName: fromUser.name,
		fromEmail: fromUser.email,
		toName: toUser.name,
		firstName: toUser.firstName,
		toEmail: toUser.email,
		locationName: location.assetName,
		locationCity: location.city,
		locationStateProv: location.stateProvince,
		locationLink: pushLink,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		.then(res => {
			console.log(`Email Invite Successfull: `, res)
		})
		.catch(err => console.log(`Send error: `, err))
}

const emailInviteExistingPartner = (fromUser, toUser, pushLink) => {
	const templateId = 'template_1yahsw9'

	const templateParams = {
		fromName: fromUser.name,
		fromCompany: fromUser.company,
		fromEmail: fromUser.email,
		toName: toUser.name,
		firstName: toUser.firstName,
		toEmail: toUser.email,
		locationLink: pushLink,
	}

	debugger

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		.then(res => {
			console.log(`Email Invite Successfull: `, res)
		})
		.catch(err => console.log(`Send error: `, err))
}

const emailInviteNewUsercc = (fromUser, toUser, location, pushLink) => {
	const templateId = 'template_3b0bz0s'

	const templateParams = {
		fromName: fromUser.name,
		fromEmail: fromUser.email,
		toName: toUser.name,
		firstName: toUser.firstName,
		toEmail: toUser.email,
		locationName: location.assetName,
		locationCity: location.city,
		locationStateProv: location.stateProvince,
		locationLink: pushLink,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		.then(res => {
			console.log(`Email CC Successfull: `, res)
		})
		.catch(err => console.log(`Send error: `, err))
}

const emailInviteExistingPartnerCC = (fromUser, toUser, location, pushLink) => {
	const templateId = 'template_vpa7bns'

	const templateParams = {
		fromName: fromUser.name,
		fromEmail: fromUser.email,
		toName: toUser.name,
		firstName: toUser.firstName,
		toEmail: toUser.email,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		.then(res => {
			console.log(`Email CC Successfull: `, res)
		})
		.catch(err => console.log(`Send error: `, err))
}

const emailPartnershipAccepted = (fromUser, toUser) => {
	const templateId = 'template_sobysua'

	const templateParams = {
		fromName: fromUser.name,
		fromCompany: fromUser.company,
		fromEmail: fromUser.email,
		toEmail: toUser.email,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		.then(res => {
			console.log(`Email Successfull: `, res)
		})
		.catch(err => console.log(`Send error: `, err))
}

const emailUpdateInviter = (fromUser, toUser, location = {}, pushLink = '') => {
	const templateId = 'template_g2q8zur'

	const templateParams = {
		fromName: fromUser.name,
		fromEmail: fromUser.email,
		toName: toUser.name,
		firstName: toUser.firstName,
		toEmail: toUser.email,
		locationName: location.assetName,
		locationCity: location.city,
		locationStateProv: location.stateProvince,
		locationLink: pushLink,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		.then(res => {
			console.log(`Email Update Successful: `, res)
		})
		.catch(err => console.log(`Send error: `, err))
}

const emailSendConfirmEmail = (toUser = {}, pushLink) => {
	const templateId = 'template_vdnospb'

	const templateParams = {
		toName: toUser.name,
		toEmail: toUser.email,
		confirmLink: pushLink,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		.then(res => {
			console.log(`Email Confirm Successful: `, res)
		})
		.catch(err => console.log(`Send error: `, err))
}

export {
	emailInviteExistingUser,
	emailReInviteExistingUser,
	emailReInviteExistingUsercc,
	emailInviteNewUser,
	emailInviteNewUsercc,
	emailUpdateInviter,
	emailInviteExistingPartner,
	emailInviteExistingPartnerCC,
	emailPartnershipAccepted,
	emailSendConfirmEmail,
}
