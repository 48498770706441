import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../slices/authSlice'
import Header from './Header'
import { userInfo } from 'os'

export interface Props {
	className?: string
}

const HeaderContainer = ({ className }: Props) => {
	const dispatch = useDispatch()
	const userLogin = useSelector((state: any) => state.userLogin)

	if (userLogin.loading) return null

	const handleLogout = () => {
		dispatch(logout())
	}

	return (
		<Header
			className={className}
			userName={userLogin.userInfo.name}
			firstTimeUser={userLogin.userInfo.firstTime}
			isGuestOnly={userLogin.userInfo.isGuestOnly}
			userImage={userLogin.userInfo.image}
			handleLogout={handleLogout}
		/>
	)
}

export default HeaderContainer
